import * as React from "react";
import { IBaseProps } from "../../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import BasicStructure from "./basic-structure";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import { IYesNoOption, yesNoOptions, YES } from "./deponierbare-abfaelle";
import { IInfoHandler, WasteInputType } from "../CreateTicketForm";
import { IDescriptionImageInfoHandler } from "./description-image";
import renderAdditional from "../common/additional";
import renderCompanyAndProcess from "../common/company-process";
import { UnwrapGrid } from "../../../layout/Tabs";
import UploadHint from "../common/upload-hint";

interface IMetalleProps extends IBaseProps {
  infoHandler: IInfoHandler;
}

interface IMetalleState {
  produced: string;
  process: string;
  originalUse: string;
  container_available: IYesNoOption | null;

  analysis: IYesNoOption | null;
  analysis_additional: string | null;
  detail: string;
  foreign_matter_exists: IYesNoOption | null;
  foreign_matter_exists_additional: string | null;
  is_licenced: IYesNoOption | null;
  is_licenced_additional: string | null;
  is_indexed: IYesNoOption | null;

  comment: string;
}

class Metalle extends React.Component<IMetalleProps, IMetalleState> {
  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {};

  constructor(props: IMetalleProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo;

    this.state = {
      produced: "",
      process: "",
      originalUse: "",
      container_available: null,

      analysis: null,
      analysis_additional: null,
      detail: "",
      foreign_matter_exists: null,
      foreign_matter_exists_additional: null,
      is_licenced: null,
      is_licenced_additional: null,
      is_indexed: null,

      comment: "",
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<IMetalleProps>,
    nextContext: any
  ): void {
    nextProps.infoHandler.getInfo = this.getInfo;
  }

  private getInfo = () => {
    const descriptionImageInfo =
      this.descriptionImageInfoHandler.getInfo &&
      this.descriptionImageInfoHandler.getInfo();

    if (
      !descriptionImageInfo ||
      descriptionImageInfo.wasteDescription.isBlank() ||
      !descriptionImageInfo.filecontent ||
      !descriptionImageInfo.photoAttachmentId ||
      this.state.process.isBlank() ||
      this.state.produced.isBlank() ||
      this.state.detail.isBlank() ||
      !this.state.is_licenced
    ) {
      console.log("getInfo validation failed", descriptionImageInfo);
      return null;
    }

    return {
      inputType: WasteInputType.METAL,
      description: descriptionImageInfo
        ? descriptionImageInfo.wasteDescription
        : "",
      photoAttachmentId: descriptionImageInfo
        ? descriptionImageInfo.photoAttachmentId
        : "",

      production: this.state.produced,
      process: this.state.process,
      usage: this.state.originalUse,
      isContainerAvailable: this.state.container_available
        ? this.state.container_available.value === YES
        : null,

      isAnalyticsAvailable: this.state.analysis
        ? this.state.analysis.value === YES
        : null,
      isAnalyticsAvailableAdditional: this.state.analysis_additional,
      size: this.state.detail,
      hasForeignSubstance: this.state.foreign_matter_exists
        ? this.state.foreign_matter_exists.value === YES
        : null,
      hasForeignSubstanceAdditional:
        this.state.foreign_matter_exists_additional,
      isLicensed: this.state.is_licenced.value === YES,
      isLicensedAdditional: this.state.is_licenced_additional,
      isIndexed: this.state.is_indexed
        ? this.state.is_indexed.value === YES
        : null,

      comment: this.state.comment,
    };
  };

  private onProducedChanged = (e: any) =>
    this.setState({ produced: e.target.value });
  private onProcessChanged = (e: any) =>
    this.setState({ process: e.target.value });
  private onOriginalUseChanged = (e: any) =>
    this.setState({ originalUse: e.target.value });
  private onIsContainerAvailableChanged = (o: IYesNoOption) =>
    this.setState({ container_available: o });

  private onAnalysisChanged = (o: IYesNoOption) =>
    this.setState({ analysis: o });
  private onAnalysisAdditionalChanged = (e: any) =>
    this.setState({ analysis_additional: e.target.value });
  private onDetailChanged = (e: any) =>
    this.setState({ detail: e.target.value });
  private onForeinMatterExistsChanged = (o: IYesNoOption) =>
    this.setState({ foreign_matter_exists: o });
  private onForeinMatterExistsAdditionalChanged = (e: any) =>
    this.setState({ foreign_matter_exists_additional: e.target.value });
  private onIsLicencedChanged = (o: IYesNoOption) =>
    this.setState({ is_licenced: o });
  private onIsLicencedAdditionalChanged = (e: any) =>
    this.setState({ is_licenced_additional: e.target.value });
  private onIsIndexedChanged = (o: IYesNoOption) =>
    this.setState({ is_indexed: o });

  private onCommentChanged = (e: any) =>
    this.setState({ comment: e.target.value });

  private renderMaterialDescription = () => {
    const { t } = this.props;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={6}>
            <Field
              name="analysis"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.analysis"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onAnalysisChanged}
                  value={this.state.analysis}
                  isSearchable={false}
                />
              )}
            </Field>
            <UploadHint analysis={this.state.analysis} />
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="analysis_additional"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.analysis_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onAnalysisAdditionalChanged}
                  value={this.state.analysis_additional}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={12}>
            <Field
              name="detail"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.detail"
              )}
              isRequired={true}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={1}
                  onChange={this.onDetailChanged}
                  value={this.state.detail}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="foreign_matter_exists"
              label={t(
                "tickets.create.fields.kunststoffe.foreign_matter_exists"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onForeinMatterExistsChanged}
                  value={this.state.foreign_matter_exists}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="foreign_matter_exists_additional"
              label={t(
                "tickets.create.fields.kunststoffe.foreign_matter_exists_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onForeinMatterExistsAdditionalChanged}
                  value={this.state.foreign_matter_exists_additional}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="is_licenced"
              label={t("tickets.create.fields.kunststoffe.is_licenced")}
              isRequired={true}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onIsLicencedChanged}
                  value={this.state.is_licenced}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="is_licenced_additional"
              label={t(
                "tickets.create.fields.kunststoffe.is_licenced_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onIsLicencedAdditionalChanged}
                  value={this.state.is_licenced_additional}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={12}>
            <Field
              name="is_indexed"
              label={t("tickets.create.fields.metalle.is_indexed")}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onIsIndexedChanged}
                  value={this.state.is_indexed}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  };

  public render() {
    return (
      <BasicStructure
        infoHandler={this.descriptionImageInfoHandler}
        companyAndProcess={renderCompanyAndProcess(
          this.props.t,
          this.onProducedChanged,
          this.state.produced,
          this.onProcessChanged,
          this.state.process,
          this.onOriginalUseChanged,
          this.state.originalUse,
          this.onIsContainerAvailableChanged,
          this.state.container_available
        )}
        materialDescription={this.renderMaterialDescription()}
        additional={renderAdditional(
          this.props.t,
          this.onCommentChanged,
          this.state.comment
        )}
        imageRequired={true}
        descriptionRequired={true}
      />
    );
  }
}

export default withTranslation()(Metalle);
