import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";
import { createTicket, createTicketVariables } from "./types/createTicket";

export default gql`
  mutation createTicket(
    $general: GeneralInput!
    $disposalWaste: DisposalWasteInput
    $plasticWaste: PlasticWasteInput
    $metalWaste: MetalWasteInput
    $paperWaste: PaperWasteInput
    $utilWaste: UtilWasteInput
    $hazardousWaste: HazardousWasteInput
    $houseWaste: HouseWasteInput
    $biogenWaste: BiogenWasteInput
    $eagWaste: EagWasteInput
    $batteryWaste: BatteryWasteInput
    $distributionUserId: String
  ) {
    createTicket(
      general: $general
      disposalWaste: $disposalWaste
      plasticWaste: $plasticWaste
      metalWaste: $metalWaste
      paperWaste: $paperWaste
      utilWaste: $utilWaste
      hazardousWaste: $hazardousWaste
      houseWaste: $houseWaste
      biogenWaste: $biogenWaste
      eagWaste: $eagWaste
      batteryWaste: $batteryWaste
      distributionUserId: $distributionUserId
    ) {
      id
    }
  }
`;

export class CreateTicketMutation extends Mutation<
  createTicket,
  createTicketVariables
> {}
