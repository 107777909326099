import * as React from "react";
import { IBaseProps } from "../../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import BasicStructure from "./basic-structure";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import { IYesNoOption, yesNoOptions, YES } from "./deponierbare-abfaelle";
import { IInfoHandler, WasteInputType } from "../CreateTicketForm";
import { IDescriptionImageInfoHandler } from "./description-image";
import renderAdditional from "../common/additional";
import renderCompanyAndProcess from "../common/company-process";
import { UnwrapGrid } from "../../../layout/Tabs";

export interface IWasteFromOption {
  value: string;
  label: string;
}

export const wasteFromOptions: IWasteFromOption[] = [
  { value: "PRODUCTION", label: "Produktion" },
  { value: "BUSINESS", label: "Gewerbe" },
  { value: "PRIVAT", label: "Haushalt" },
];

interface IEagProps extends IBaseProps {
  infoHandler: IInfoHandler;
}

interface IEagState {
  produced: string;
  process: string;
  originalUse: string;
  container_available: IYesNoOption | null;

  foreign_matter_exists: IYesNoOption | null;
  foreign_matter_exists_additional: string | null;
  waste_from: IWasteFromOption | null;

  comment: string;
}

class Eag extends React.Component<IEagProps, IEagState> {
  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {};

  constructor(props: IEagProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo;

    this.state = {
      produced: "",
      process: "",
      originalUse: "",
      container_available: null,

      foreign_matter_exists: null,
      foreign_matter_exists_additional: null,
      waste_from: null,

      comment: "",
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<IEagProps>,
    nextContext: any
  ): void {
    nextProps.infoHandler.getInfo = this.getInfo;
  }

  private getInfo = () => {
    const descriptionImageInfo =
      this.descriptionImageInfoHandler.getInfo &&
      this.descriptionImageInfoHandler.getInfo();

    if (
      !descriptionImageInfo ||
      descriptionImageInfo.wasteDescription.isBlank() ||
      !descriptionImageInfo.filecontent ||
      !descriptionImageInfo.photoAttachmentId ||
      this.state.process.isBlank() ||
      this.state.produced.isBlank()
    ) {
      console.log("getInfo validation failed", descriptionImageInfo);
      return null;
    }

    return {
      inputType: WasteInputType.EAG,
      description: descriptionImageInfo
        ? descriptionImageInfo.wasteDescription
        : "",
      photoAttachmentId: descriptionImageInfo
        ? descriptionImageInfo.photoAttachmentId
        : "",

      production: this.state.produced,
      process: this.state.process,
      usage: this.state.originalUse,
      isContainerAvailable: this.state.container_available
        ? this.state.container_available.value === YES
        : null,

      hasForeignSubstance: this.state.foreign_matter_exists
        ? this.state.foreign_matter_exists.value === YES
        : null,
      hasForeignSubstanceAdditional:
        this.state.foreign_matter_exists_additional,
      wasteFrom: this.state.waste_from ? this.state.waste_from.value : null,

      comment: this.state.comment,
    };
  };

  private onProducedChanged = (e: any) =>
    this.setState({ produced: e.target.value });
  private onProcessChanged = (e: any) =>
    this.setState({ process: e.target.value });
  private onOriginalUseChanged = (e: any) =>
    this.setState({ originalUse: e.target.value });
  private onIsContainerAvailableChanged = (o: IYesNoOption) =>
    this.setState({ container_available: o });

  private onForeinMatterExistsChanged = (o: IYesNoOption) =>
    this.setState({ foreign_matter_exists: o });
  private onForeinMatterExistsAdditionalChanged = (e: any) =>
    this.setState({ foreign_matter_exists_additional: e.target.value });
  private onWasteFromChanged = (o: IWasteFromOption) =>
    this.setState({ waste_from: o });

  private onCommentChanged = (e: any) =>
    this.setState({ comment: e.target.value });

  private renderMaterialDescription = () => {
    const { t } = this.props;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={6}>
            <Field
              name="foreign_matter_exists"
              label={t(
                "tickets.create.fields.kunststoffe.foreign_matter_exists"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onForeinMatterExistsChanged}
                  value={this.state.foreign_matter_exists}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="foreign_matter_exists_additional"
              label={t(
                "tickets.create.fields.kunststoffe.foreign_matter_exists_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onForeinMatterExistsAdditionalChanged}
                  value={this.state.foreign_matter_exists_additional}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={12}>
            <Field
              name="waste_from"
              label={t("tickets.create.fields.eag.waste_from")}
              isRequired={false}
            >
              {(props: any) => (
                <Select
                  options={wasteFromOptions}
                  onChange={this.onWasteFromChanged}
                  value={this.state.waste_from}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  };

  public render() {
    return (
      <BasicStructure
        infoHandler={this.descriptionImageInfoHandler}
        companyAndProcess={renderCompanyAndProcess(
          this.props.t,
          this.onProducedChanged,
          this.state.produced,
          this.onProcessChanged,
          this.state.process,
          this.onOriginalUseChanged,
          this.state.originalUse,
          this.onIsContainerAvailableChanged,
          this.state.container_available
        )}
        materialDescription={this.renderMaterialDescription()}
        additional={renderAdditional(
          this.props.t,
          this.onCommentChanged,
          this.state.comment
        )}
        imageRequired={true}
        descriptionRequired={true}
      />
    );
  }
}

export default withTranslation()(Eag);
