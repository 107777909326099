import styled from "styled-components";

export const TabWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
`;

export const TabUnwrapGrid = styled.div`
  margin: 0 -8px;

  margin-top: -16px;

  & > [spacing="cosy"] {
    padding: 0;
    margin: 0;
  }
`;

export const UnwrapGrid = styled.div`
  margin: 0 -8px;

  & > [spacing="cosy"] {
    padding: 0;
    margin: 0;
  }
`;

export const TabsWrapper = styled.div`
  & > div > div:first-child {
    font-size: 0.8571428571428571em;
    font-weight: 600;

    & > span:first-child {
      left: 0;
      right: 0;
    }

    & > div {
      & > div:first-child {
        padding-left: 0;

        & > span {
          left: 0;
        }
      }

      & > div:last-child {
        padding-right: 0;

        & > span {
          right: 0;
        }
      }
    }
  }
`;
