import { Grid, GridColumn } from "@atlaskit/page";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { AuthConsumer } from "../../../context/AuthContext";
import EditCheckbox from "../../pages/tickets/fields/EditCheckbox";
import {
  CustomCheckboxNumberField,
  INumberSelection,
} from "../CustomCheckboxNumberField";
import {
  CustomCheckboxTextField,
  ITextSelection,
} from "../CustomCheckboxTextField";
import { CustomTextArea } from "../CustomTextArea";
import { FormSection } from "../FormSection";
import { IPartnerServiceSection } from "./types/claim-types";
import { UnwrapGrid } from "../../layout/Tabs";

interface IPartnerServiceFormSectionProps {
  section: IPartnerServiceSection;
  onChange: (section: IPartnerServiceSection) => void;
  disabled?: boolean;
}

export const PartnerServiceFormSection: VFC<IPartnerServiceFormSectionProps> = (
  props
) => {
  const { section, onChange, disabled } = props;
  const { t } = useTranslation();

  const onChangePriceMismatch = (_: string, value: Boolean) => {
    const changedPartnerServiceSection: IPartnerServiceSection = {
      ...section,
      priceMismatch: !!value,
    };
    onChange(changedPartnerServiceSection);
  };

  const onChangeInvoicePrice = (invoicePriceSelection: INumberSelection) => {
    const changedInvoicePrice = section.invoicePrice.map((selection) => {
      if (selection.name === invoicePriceSelection.name) {
        return {
          name: selection.name,
          isSelected: invoicePriceSelection.isSelected,
          value: invoicePriceSelection.value,
        };
      } else {
        return selection;
      }
    });
    let changedPartnerServiceSection = {
      ...section,
    };
    changedPartnerServiceSection.invoicePrice = changedInvoicePrice;
    onChange(changedPartnerServiceSection);
  };

  const onChangeComment = (event: any) => {
    const changedPartnerServiceSection: IPartnerServiceSection = {
      ...section,
      comment: event?.target?.value ?? "",
    };
    onChange(changedPartnerServiceSection);
  };

  const onChangeMissingBilledMaterial = (_: string, value: Boolean) => {
    const changedPartnerServiceSection: IPartnerServiceSection = {
      ...section,
      missingBilledMaterial: !!value,
    };
    onChange(changedPartnerServiceSection);
  };

  const onChangeDeviationPosition = (
    deviationPositionSelection: ITextSelection
  ) => {
    const changedDeviationPosition = section.deviationPosition.map(
      (selection) => {
        if (selection.name === deviationPositionSelection.name) {
          return {
            name: selection.name,
            isSelected: deviationPositionSelection.isSelected,
            value: deviationPositionSelection.value,
          };
        } else {
          return selection;
        }
      }
    );
    let changedPartnerServiceSection = {
      ...section,
    };
    changedPartnerServiceSection.deviationPosition = changedDeviationPosition;
    onChange(changedPartnerServiceSection);
  };

  const disablePriceMismatch = () => section.priceMismatch === false;

  return (
    <AuthConsumer>
      {({ hasAbility }) => (
        <FormSection
          title={t("create_claim.form.partner_service_section.title")}
        >
          <GridColumn medium={6}>
            <UnwrapGrid>
              <GridColumn medium={12}>
                <EditCheckbox
                  translation="create_claim.form.partner_service_section.price_mismatch"
                  name="price_mismatch"
                  hideHelper={true}
                  onChange={onChangePriceMismatch}
                  selected={section.priceMismatch}
                  disabled={disabled}
                />
              </GridColumn>

              <Grid layout="max-width">
                <GridColumn medium={12}>
                  <div
                    style={{
                      fontWeight: 600,
                      color: "#5E6C84",
                      fontSize: 12,
                      paddingTop: "12px",
                    }}
                  >
                    {t(
                      "create_claim.form.partner_service_section.invoice_price.title"
                    )}
                  </div>
                </GridColumn>
                {section.invoicePrice.map((selection) => {
                  return (
                    <CustomCheckboxNumberField
                      translation={`create_claim.form.partner_service_section.invoice_price.${selection.name}`}
                      selection={selection as INumberSelection}
                      onChange={onChangeInvoicePrice}
                      disabled={disabled}
                    />
                  );
                })}
              </Grid>

              <GridColumn medium={12}>
                <CustomTextArea
                  name="comment"
                  translation="create_claim.form.partner_service_section.comment"
                  disabled={disablePriceMismatch() || disabled}
                  value={section.comment}
                  onChange={onChangeComment}
                />
              </GridColumn>
            </UnwrapGrid>
          </GridColumn>

          <GridColumn medium={6}>
            <UnwrapGrid>
              <GridColumn medium={12}>
                <EditCheckbox
                  translation="create_claim.form.partner_service_section.missing_billed_material"
                  name="missing_billed_material"
                  hideHelper={true}
                  onChange={onChangeMissingBilledMaterial}
                  selected={section.missingBilledMaterial}
                  disabled={disabled}
                />
              </GridColumn>

              <Grid layout="max-width">
                <GridColumn medium={12}>
                  <div
                    style={{
                      fontWeight: 600,
                      color: "#5E6C84",
                      fontSize: 12,
                      paddingTop: "12px",
                    }}
                  >
                    {t(
                      "create_claim.form.partner_service_section.deviation_position.title"
                    )}
                  </div>
                </GridColumn>
                {section.deviationPosition.map((selection) => {
                  return (
                    <CustomCheckboxTextField
                      translation={`create_claim.form.partner_service_section.deviation_position.${selection.name}`}
                      selection={selection as ITextSelection}
                      onChange={onChangeDeviationPosition}
                      disabled={disabled}
                    />
                  );
                })}
              </Grid>
            </UnwrapGrid>
          </GridColumn>
        </FormSection>
      )}
    </AuthConsumer>
  );
};
