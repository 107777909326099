import * as React from "react";
import { IBaseProps } from "../../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import BasicStructure from "./basic-structure";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import TextArea from "@atlaskit/textarea";
import Select from "@atlaskit/select";
import { IYesNoOption, yesNoOptions, YES } from "./deponierbare-abfaelle";
import { IDescriptionImageInfoHandler } from "./description-image";
import { IInfoHandler, WasteInputType } from "../CreateTicketForm";
import renderAdditional from "../common/additional";
import { UnwrapGrid } from "../../../layout/Tabs";
import UploadHint from "../common/upload-hint";

interface IDeponierbareAbfaelleProps extends IBaseProps {
  infoHandler: IInfoHandler;
}

interface IDeponierbareAbfaelleState {
  produced: string;
  process: string;
  processSelection?: IProcessOption | null;
  sample: IYesNoOption | null;
  sampleTakenFrom?: ISampleTakenOption | null;
  sampleGivenTo: string;
  sampleDeliveredAt: string;

  smell: ISmellOption | null;
  consistency: IConsistencyOption | null;
  analysis: IYesNoOption | null;

  comment: string | null;
}

interface IConsistencyOption {
  value: string;
  label: string;
}

const consistencyOptions: IConsistencyOption[] = [
  { value: "fest", label: "fest" },
  { value: "pastös", label: "pastös" },
  { value: "fluessig", label: "flüssig" },
  { value: "staubförmig", label: "staubförmig" },
];

interface IProcessOption {
  value: string;
  label: string;
}

const processOptions: IProcessOption[] = [
  { value: "BETRIEBSMITTELABFALL", label: "Betriebsmittelabfall" },
  { value: "PRODUKTIONSABFALL", label: "Produktionsabfall" },
];

interface ISampleTakenOption {
  value: string;
  label: string;
}

const sampleTakenOptions: ISampleTakenOption[] = [
  { value: "saubermacher", label: "Saubermacher" },
  { value: "kunde", label: "Kunde" },
];

interface ISmellOption {
  value: string;
  label: string;
}

const smellOptions: ISmellOption[] = [
  { value: "INTENSIV", label: "intensiv" },
  { value: "SCHWACH", label: "schwach" },
  { value: "OHNE", label: "ohne" },
  { value: "LOESEMITTEL", label: "nach Lösemittel" },
];

class GefaehrlicheAbfaelle extends React.Component<
  IDeponierbareAbfaelleProps,
  IDeponierbareAbfaelleState
> {
  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {};

  constructor(props: IDeponierbareAbfaelleProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo;

    this.state = {
      produced: "",
      process: "",
      sample: null,
      sampleDeliveredAt: "",
      sampleGivenTo: "",

      smell: null,
      consistency: null,
      analysis: null,

      comment: null,
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<IDeponierbareAbfaelleProps>,
    nextContext: any
  ): void {
    nextProps.infoHandler.getInfo = this.getInfo;
  }

  private getInfo = () => {
    const descriptionImageInfo =
      this.descriptionImageInfoHandler.getInfo &&
      this.descriptionImageInfoHandler.getInfo();

    if (
      !descriptionImageInfo ||
      descriptionImageInfo.wasteDescription.isBlank() ||
      !this.state.sample ||
      !this.state.consistency ||
      !this.state.analysis
    ) {
      return null;
    }

    return {
      inputType: WasteInputType.HAZARDOUS,
      description: descriptionImageInfo.wasteDescription,
      photoAttachmentId: descriptionImageInfo
        ? descriptionImageInfo.photoAttachmentId
        : "",
      production: this.state.produced,
      process: this.state.process,
      processSelection:
        this.state.processSelection && this.state.processSelection.value,
      isSampleAvailable: this.state.sample.value === YES,
      sampleCreator:
        this.state.sampleTakenFrom && this.state.sampleTakenFrom.value,
      sampleRecipient: this.state.sampleGivenTo,
      sampleReceivedAt: this.state.sampleDeliveredAt,
      smell: this.state.smell ? this.state.smell.value : null,
      consistency: this.state.consistency.value,
      isAnalyticsAvailable: this.state.analysis.value === YES,
      comment: this.state.comment,
    };
  };

  private onProducedChanged = (e: any) =>
    this.setState({ produced: e.target.value });
  private onProcessChanged = (e: any) =>
    this.setState({ process: e.target.value });
  private onProcessSelectionChanged = (o: IProcessOption) =>
    this.setState({ processSelection: o });

  private onSampleChange = (o: IYesNoOption) => this.setState({ sample: o });
  private onSampleDeliveredAtChanged = (e: any) =>
    this.setState({ sampleDeliveredAt: e.target.value });
  private onSampleGivenToChanged = (e: any) =>
    this.setState({ sampleGivenTo: e.target.value });
  private onSmellChanged = (o: ISmellOption) => this.setState({ smell: o });

  private onConsistencyChanged = (e: IConsistencyOption) =>
    this.setState({ consistency: e });
  private onAnalysisChanged = (o: IYesNoOption) =>
    this.setState({ analysis: o });
  private onSampleTakenFromChanged = (o: ISampleTakenOption) =>
    this.setState({ sampleTakenFrom: o });

  private onCommentChanged = (e: any) =>
    this.setState({ comment: e.target.value });

  private renderCompanyAndProcess = () => {
    const { t } = this.props;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={12}>
            <Field
              name="produced"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.company_process.produced"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={5}
                  onChange={this.onProducedChanged}
                  value={this.state.produced}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="process"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.company_process.process"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={5}
                  onChange={this.onProcessChanged}
                  value={this.state.process}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="process_selection"
              label={t(
                "tickets.create.fields.gefaehrliche_abfaelle.process_selection"
              )}
            >
              {(props: any) => (
                <Select
                  options={processOptions}
                  onChange={this.onProcessSelectionChanged}
                  value={this.state.processSelection}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="sample"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.company_process.sample"
              )}
              isRequired={true}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onSampleChange}
                  value={this.state.sample}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="sample_taken_from"
              label={t(
                "tickets.create.fields.gefaehrliche_abfaelle.sample_taken_from"
              )}
            >
              {(props: any) => (
                <Select
                  options={sampleTakenOptions}
                  onChange={this.onSampleTakenFromChanged}
                  value={this.state.sampleTakenFrom}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="sample_given_to"
              label={t(
                "tickets.create.fields.gefaehrliche_abfaelle.sample_given_to"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onSampleGivenToChanged}
                  value={this.state.sampleGivenTo}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="sample_delivered_at"
              label={t(
                "tickets.create.fields.gefaehrliche_abfaelle.sample_delivered_at"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onSampleDeliveredAtChanged}
                  value={this.state.sampleDeliveredAt}
                />
              )}
            </Field>
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  };

  private renderMaterialDescription = () => {
    const { t } = this.props;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={6}>
            <Field
              name="smell"
              label={t("tickets.create.fields.gefaehrliche_abfaelle.smell")}
            >
              {(props: any) => (
                <Select
                  options={smellOptions}
                  onChange={this.onSmellChanged}
                  value={this.state.smell}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="consistency"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.consistency"
              )}
              isRequired={true}
            >
              {(props: any) => (
                <Select
                  options={consistencyOptions}
                  onChange={this.onConsistencyChanged}
                  value={this.state.consistency}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="analysis"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.analysis"
              )}
              isRequired={true}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onAnalysisChanged}
                  value={this.state.analysis}
                  isSearchable={false}
                />
              )}
            </Field>
            <UploadHint analysis={this.state.analysis} />
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  };

  public render() {
    return (
      <div>
        <BasicStructure
          infoHandler={this.descriptionImageInfoHandler}
          companyAndProcess={this.renderCompanyAndProcess()}
          materialDescription={this.renderMaterialDescription()}
          additional={renderAdditional(
            this.props.t,
            this.onCommentChanged,
            this.state.comment
          )}
          imageRequired={false}
          descriptionRequired={true}
        />
      </div>
    );
  }
}

export default withTranslation()(GefaehrlicheAbfaelle);
