import * as React from "react";
import { Redirect } from "react-router";
import { IBaseProps } from "../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import Button, { ButtonGroup } from "@atlaskit/button";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import LockIcon from "@atlaskit/icon/glyph/lock";
import PageHeader from "@atlaskit/page-header";
import Grid from "@material-ui/core/Grid";

import { AuthConsumer } from "../../../context/AuthContext";
import HeaderInfo from "../../styled/HeaderInfo";

interface ILogoutProps extends IBaseProps {
  history: any;
}

interface ILogoutState {
  redirectLogout: boolean;
}

class Logout extends React.Component<ILogoutProps, ILogoutState> {
  constructor(props: ILogoutProps) {
    super(props);
    this.state = {
      redirectLogout: false,
    };
  }

  handleLogout = (logout: Function) => {
    logout();
    this.setState({ redirectLogout: true });
  };

  public render() {
    const { t } = this.props;
    return (
      <AuthConsumer>
        {({ isAuthenticated, logout }) => (
          <React.Fragment>
            <PageHeader>
              {t("logout.title")}
              <HeaderInfo>{t("logout.subtitle")}</HeaderInfo>
            </PageHeader>

            {this.state.redirectLogout || !isAuthenticated() ? (
              <Redirect to="/login" />
            ) : null}

            <Grid container spacing={24} direction="column">
              <Grid container item spacing={0} justify="center">
                <Grid item xs={12} md={6}>
                  <h1>{t("logout.info.greeting")}</h1>
                  <p>{t("logout.info.text")}</p>

                  <ButtonGroup>
                    <Button
                      iconBefore={
                        <ArrowLeftIcon label={t("logout.action.logout")} />
                      }
                      onClick={this.props.history.goBack}
                    >
                      {t("logout.action.logout")}
                    </Button>
                    <Button
                      appearance="primary"
                      iconBefore={
                        <LockIcon label={t("logout.action.cancel")} />
                      }
                      onClick={this.handleLogout.bind(this, logout)}
                    >
                      {t("logout.action.cancel")}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </AuthConsumer>
    );
  }
}

export default withTranslation()(Logout);
