import React, { useState } from "react";
import "url-search-params-polyfill";
import PageHeader from "@atlaskit/page-header";
import { TicketLane } from "./partials/TicketLane";
import {
  TicketsQuery,
  TICKETS_QUERY,
} from "../../../api/graphql/queries/tickets";
import { Tickets_tickets as Ticket } from "../../../api/graphql/queries/types/Tickets";
import { TicketLaneTypes } from "../../../domain/models/ticket-lane-type";
import PageLoadingIndicator from "../../partials/PageLoadingIndicator";
import { Board } from "../../styled/Board";
import { toast } from "react-toastify";
import { GridColumn } from "@atlaskit/page";
import { ButtonGroup } from "@atlaskit/button";
import Button from "@atlaskit/button";

interface IDashboardProps {}

export const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
  const [showCompletedTickets, setShowCompletedTickets] = useState(false);
  return (
    <div className="page-layout--wrapper">
      <PageHeader
        actions={
          <ButtonGroup>
            <Button
              appearance="secondary"
              type="submit"
              onClick={() => setShowCompletedTickets(!showCompletedTickets)}
            >
              {showCompletedTickets
                ? "Kürzlich abgeschl. ausblenden"
                : "Kürzlich abgeschlossene anzeigen"}
            </Button>
          </ButtonGroup>
        }
      >
        Dashboard
      </PageHeader>
      <TicketsQuery query={TICKETS_QUERY} variables={{ showCompletedTickets }}>
        {({ data, loading, error }) => {
          if (data && data.tickets) {
            console.log("data.tickets", data.tickets);
            return (
              <Board>
                <TicketLane
                  laneName="Stoffstrom"
                  tickets={data.tickets.filter(
                    (ticket) =>
                      ticket.assignedRole === TicketLaneTypes.STOFFSTROM &&
                      ticket.status !== "COMPLETED" &&
                      ticket.status !== "CANCELED"
                  )}
                  sublanes={{
                    "Nicht zugewiesen": (ticket: Ticket) =>
                      !ticket.assignedUser,
                    "In Bearbeitung": (ticket: Ticket) =>
                      !!ticket.assignedUser &&
                      !(
                        (ticket.utilizations &&
                          ticket.utilizations.length > 0) ||
                        false
                      ),
                    Dokumentiert: (ticket: Ticket) =>
                      !!ticket.assignedUser &&
                      ((ticket.utilizations &&
                        ticket.utilizations.length > 0) ||
                        false),
                  }}
                />
                <TicketLane
                  laneName="Labor"
                  tickets={data.tickets.filter(
                    (ticket) =>
                      ticket.assignedRole === TicketLaneTypes.LABOR &&
                      ticket.status !== "COMPLETED" &&
                      ticket.status !== "CANCELED"
                  )}
                  sublanes={{
                    Analyse: (ticket: Ticket) => true,
                  }}
                />
                <TicketLane
                  laneName="Vertrieb"
                  tickets={data.tickets.filter(
                    (ticket) =>
                      ticket.assignedRole === TicketLaneTypes.VERTRIEB &&
                      ticket.status !== "COMPLETED" &&
                      ticket.status !== "CANCELED"
                  )}
                  sublanes={{
                    Rückfrage: (ticket: Ticket) =>
                      (!ticket.utilizations ||
                        ticket.utilizations.length <= 0) &&
                      !ticket.requestMaterialAnalysis,
                    "Probe angefordert": (ticket: Ticket) =>
                      !!ticket.requestMaterialAnalysis,
                    Dokumentiert: (ticket: Ticket) =>
                      (ticket.utilizations && ticket.utilizations.length > 0) ||
                      false,
                  }}
                />
                {showCompletedTickets && (
                  <React.Fragment>
                    <TicketLane
                      laneName={"Kürzlich abgeschlossen"}
                      tickets={data.tickets.filter(
                        (ticket) =>
                          ticket.status === "COMPLETED" ||
                          ticket.status === "CANCELED"
                      )}
                      sublanes={{
                        Dokumentiert: (ticket: Ticket) =>
                          ticket.status === "COMPLETED",
                        Storniert: (ticket: Ticket) =>
                          ticket.status === "CANCELED",
                      }}
                    />
                  </React.Fragment>
                )}
              </Board>
            );
          }

          if (error) {
            toast.error("Daten konnten nicht geladen werden.");
            return (
              <GridColumn medium={12}>
                <div
                  style={{ flex: 1, justifyContent: "center", display: "flex" }}
                >
                  <h2>Daten konnten nicht geladen werden.</h2>
                </div>
              </GridColumn>
            );
          }
          return <PageLoadingIndicator />;
        }}
      </TicketsQuery>
    </div>
  );
};
