import React, { Fragment } from "react";
import "url-search-params-polyfill";
import ticketsHistoryQuery, {
  TicketHistoryQuery,
} from "../../../../api/graphql/queries/ticketHistory";
import { Ticket_ticket } from "../../../../api/graphql/queries/types/Ticket";
import { TicketHistoryEntry } from "./TicketHistoryEntry";

interface ITicketHistoryProps {
  ticket: Ticket_ticket;
}

export const TicketHistory: React.FunctionComponent<ITicketHistoryProps> = (
  props
) => {
  const { ticket } = props;
  return (
    <Fragment>
      <TicketHistoryQuery
        query={ticketsHistoryQuery}
        variables={{ ticketId: ticket.id }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <div>Verlauf wird geladen ...</div>;
          }
          if (data && data.ticketHistory && data.ticketHistory.length > 0) {
            return (
              <ul>
                {data.ticketHistory.map((historyEntry, index) => (
                  <TicketHistoryEntry key={index} historyEntry={historyEntry} />
                ))}
              </ul>
            );
          } else if (
            data &&
            data.ticketHistory &&
            data.ticketHistory.length <= 0
          ) {
            return (
              <p>Es konnte kein Verlauf für dieses Ticket gefunden werden</p>
            );
          }
          return <div>Verlauf nicht verfügbar</div>;
        }}
      </TicketHistoryQuery>
    </Fragment>
  );
};
