import * as React from "react";
import i18next from "i18next";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import TextArea from "@atlaskit/textarea";
import {
  yesNoOptions,
  IYesNoOption,
} from "../ssm-specific/deponierbare-abfaelle";
import { UnwrapGrid } from "../../../layout/Tabs";

export default (
  t: i18next.TFunction,
  onProducedChanged: Function | null,
  produced: string | null,
  onProcessChanged: Function | null,
  process: string | null,
  onOriginalUseChanged: Function | null,
  originalUse: string | null,
  onIsContainerAvailableChanged: Function | null = null,
  isContainerAvailable: IYesNoOption | null = null
) => {
  return (
    <UnwrapGrid>
      <Grid layout="max-width">
        {onProducedChanged && (
          <GridColumn medium={6}>
            <Field
              name="produced"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.company_process.produced"
              )}
              isRequired={true}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={5}
                  onChange={onProducedChanged}
                  value={produced}
                />
              )}
            </Field>
          </GridColumn>
        )}

        {onProcessChanged && (
          <GridColumn medium={6}>
            <Field
              name="process"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.company_process.process"
              )}
              isRequired={true}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={5}
                  onChange={onProcessChanged}
                  value={process}
                />
              )}
            </Field>
          </GridColumn>
        )}
        {onOriginalUseChanged && (
          <GridColumn medium={6}>
            <Field
              name="original_use"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.company_process.original_use"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={5}
                  onChange={onOriginalUseChanged}
                  value={originalUse}
                />
              )}
            </Field>
          </GridColumn>
        )}
        {onIsContainerAvailableChanged && (
          <GridColumn medium={6}>
            <Field
              name="container_available"
              label={t(
                "tickets.create.fields.metalle.company_process.container_available"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={onIsContainerAvailableChanged}
                  value={isContainerAvailable}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
        )}
      </Grid>
    </UnwrapGrid>
  );
};
