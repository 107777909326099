import { gql } from "apollo-boost";
import { DistributionUsers } from "./types/DistributionUsers";
import { useQuery } from "react-apollo-hooks";

const query = gql`
  query DistributionUsers {
    distributionUsers {
      id
      email
      name
      active
    }
  }
`;

export const useDistributionUsersQuery = () =>
  useQuery<DistributionUsers>(query);
