import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import store from "store";
import Tooltip from "@atlaskit/tooltip";
import AddIcon from "@atlaskit/icon/glyph/add";
import IssuesIcon from "@atlaskit/icon/glyph/issues";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import Page, { Grid, GridColumn } from "@atlaskit/page";
import SwitcherIcon from "@atlaskit/icon/glyph/switcher";
import JiraLabsIcon from "@atlaskit/icon/glyph/jira/labs";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import SearchIcon from "@atlaskit/icon/glyph/search";
import BoardIcon from "@atlaskit/icon/glyph/board";
import BillingIcon from "@atlaskit/icon/glyph/billing";
import Navigation, {
  AkContainerNavigationNested,
  AkCreateDrawer,
  AkNavigationItem,
  presetThemes,
} from "@atlaskit/navigation";
import { Link } from "react-router-dom";
import { AuthConsumer } from "../../context/AuthContext";
import { getLinkToWomByGuessing } from "../../utils/getLinkToWomByGuessing";
import { Abilities } from "../../domain/models/abilities";
import GridSpacer from "../pages/tickets/partials/GridSpacer";

const GlobalSetting = ({
  openDrawer,
}: {
  openDrawer: (name: string) => void;
}) => (
  <Tooltip position="right" content="Einstellungen">
    <div
      onClick={() => {
        openDrawer("settings");
      }}
    >
      <SettingsIcon
        label="Einstellungen"
        secondaryColor="inherit"
        size="medium"
      />
    </div>
  </Tooltip>
);

const GlobalWom = ({ token }: { token: string }) => (
  <Tooltip position="right" content="Zu Wom">
    <div
      onClick={() => {
        window.open(getLinkToWomByGuessing(token, "https") || "", "wom");
      }}
    >
      <SwitcherIcon label="zu Wom" secondaryColor="inherit" size="medium" />
    </div>
  </Tooltip>
);

const BackIcon = (
  <Tooltip position="right" content="Schließen">
    <ArrowLeftIcon label="Close" size="medium" />
  </Tooltip>
);

const wrapInTooltip = (
  isCollaped: boolean,
  icon: React.ReactNode,
  title: string
) => {
  return isCollaped ? (
    <Tooltip position="right" content={title}>
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};

interface ICustomLinkProps {
  className: string | undefined;
  href: string;
}

class CustomLink extends Component<ICustomLinkProps> {
  render() {
    return (
      <Link className={this.props.className} to={this.props.href}>
        {this.props.children}
      </Link>
    );
  }
}

export default class PageLayout extends Component {
  state = {
    openDrawer: null,
    isCollapsedNavigation:
      !!store.get("is_collapsed_navigation") ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
  };

  openDrawer = (name: string) => {
    this.setState({ openDrawer: name });
  };

  closeDrawer = () => {
    this.setState({ openDrawer: null });
  };

  resize = (resizeState: any) => {
    const isCollapsedNavigation = !resizeState.isOpen;
    store.set("is_collapsed_navigation", isCollapsedNavigation);
    this.setState({
      isCollapsedNavigation,
      width: Math.max(370, resizeState.width),
    });
  };

  createSettingsDrawer = (
    hasAbility: (ability: string) => boolean,
    token: string | undefined,
    userId: string | undefined
  ) => (
    <AkCreateDrawer
      backIcon={BackIcon}
      isOpen={this.state.openDrawer === "settings"}
      key="settings"
      onBackButton={this.closeDrawer}
      primaryIcon={<IssuesIcon label="Ticketsystem" size="large" />}
    >
      {hasAbility("ADMIN") && (
        <AkNavigationItem
          text="Benutzer verwalten"
          target={"wom"}
          onClick={() =>
            window.open(
              getLinkToWomByGuessing(token, "https", "/users") || "",
              "wom"
            )
          }
        />
      )}
      <AkNavigationItem
        text="Passwort ändern"
        linkComponent={CustomLink}
        href="/change-password"
      />
      <AkNavigationItem
        text="Abmelden"
        linkComponent={CustomLink}
        href="/logout"
      />
    </AkCreateDrawer>
  );

  createGlobalIcons = (token: string) => [
    <GlobalWom token={token} />,
    <GridSpacer />,
    <GlobalSetting openDrawer={this.openDrawer} />,
  ];

  render() {
    const colors = presetThemes.settings;

    const authenticatedNavigation = (
      <AuthConsumer>
        {({ hasAbility, token, userId }) =>
          hasAbility && (
            <Navigation
              drawers={[this.createSettingsDrawer(hasAbility, token, userId)]}
              globalTheme={colors}
              containerTheme={colors}
              isOpen={!this.state.isCollapsedNavigation}
              onResize={this.resize}
              globalPrimaryIcon={
                <IssuesIcon label="Stoffstrom Ticketsystem" size="large" />
              }
              globalPrimaryItemHref="/"
              globalPrimaryActions={this.createGlobalIcons(token || "")}
            >
              <AkContainerNavigationNested
                stack={[
                  [
                    hasAbility(Abilities.ADMIN) ||
                    !hasAbility(Abilities.PARTNER_SERVICE) ? (
                      <AkNavigationItem
                        text="Ticket erstellen"
                        key="create"
                        icon={wrapInTooltip(
                          this.state.isCollapsedNavigation,
                          <AddIcon label="Ticket erstellen" size="medium" />,
                          "Ticket erstellen"
                        )}
                        linkComponent={CustomLink}
                        href="/tickets/create"
                      />
                    ) : null,
                    <AkNavigationItem
                      text="Dashboard"
                      key="dashboard"
                      icon={wrapInTooltip(
                        this.state.isCollapsedNavigation,
                        <BoardIcon label="Dashboard" size="medium" />,
                        "Dashboard"
                      )}
                      linkComponent={CustomLink}
                      href="/dashboard"
                    />,
                    hasAbility(Abilities.ADMIN) ||
                    hasAbility(Abilities.LABOR) ? (
                      <AkNavigationItem
                        text="Labor/Produktion"
                        key="labor"
                        icon={wrapInTooltip(
                          this.state.isCollapsedNavigation,
                          <JiraLabsIcon label="Labor" size="medium" />,
                          "Labor"
                        )}
                        linkComponent={CustomLink}
                        href="/role/labor"
                      />
                    ) : null,
                    <AkNavigationItem
                      text="Ticketsuche"
                      key="search"
                      icon={wrapInTooltip(
                        this.state.isCollapsedNavigation,
                        <SearchIcon label="Ticketsuche" size="medium" />,
                        "Ticketsuche"
                      )}
                      linkComponent={CustomLink}
                      href="/tickets/search"
                    />,
                    (hasAbility(Abilities.ADMIN) ||
                      hasAbility(Abilities.STOFFSTROM) ||
                      hasAbility(Abilities.PARTNER_SERVICE)) && (
                      <>
                        <hr
                          style={{ border: "0.5px solid white", width: "100%" }}
                        />
                        <AkNavigationItem
                          text="Reklamation erstellen"
                          key="create_claim"
                          icon={wrapInTooltip(
                            this.state.isCollapsedNavigation,
                            <AddIcon
                              label="Reklamation erstellen"
                              size="medium"
                            />,
                            "Reklamation erstellen"
                          )}
                          linkComponent={CustomLink}
                          href="/claims/create"
                        />
                      </>
                    ),
                    (hasAbility(Abilities.ADMIN) ||
                      hasAbility(Abilities.STOFFSTROM) ||
                      hasAbility(Abilities.PARTNER_SERVICE)) && (
                      <AkNavigationItem
                        text="Reklamationen"
                        key="claims"
                        icon={wrapInTooltip(
                          this.state.isCollapsedNavigation,
                          <BillingIcon label="Reklamationen" size="medium" />,
                          "Reklamationen"
                        )}
                        linkComponent={CustomLink}
                        href="/claims"
                      />
                    ),
                  ],
                ]}
              />
            </Navigation>
          )
        }
      </AuthConsumer>
    );

    const BaseGrid = ({
      children,
      isAuthenticated,
    }: {
      children: any;
      isAuthenticated: boolean;
    }) => {
      if (isAuthenticated) {
        return (
          <Page style={{ overflow: "auto" }}>
            <Grid layout="max-width">
              <GridColumn medium={12}>{children}</GridColumn>
            </Grid>
          </Page>
        );
      }

      return <Fragment>{children}</Fragment>;
    };

    return (
      <AuthConsumer>
        {({ isAuthenticated }) =>
          isAuthenticated && (
            <Page
              navigation={isAuthenticated() ? authenticatedNavigation : null}
            >
              {window.location.pathname !== "/login" &&
              window.location.pathname !== "/status" &&
              window.location.pathname !== "/change-password" &&
              !isAuthenticated() ? (
                <Redirect to="/login" />
              ) : null}
              <BaseGrid isAuthenticated={isAuthenticated()}>
                {this.props.children}
              </BaseGrid>
            </Page>
          )
        }
      </AuthConsumer>
    );
  }
}
