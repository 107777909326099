import * as React from "react";
import { IBaseProps } from "../../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import BasicStructure from "./basic-structure";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import { IYesNoOption, yesNoOptions, YES } from "./deponierbare-abfaelle";
import { IConsistencyOption } from "./deponierbare-abfaelle";
import { IInfoHandler, WasteInputType } from "../CreateTicketForm";
import { IDescriptionImageInfoHandler } from "./description-image";
import renderAdditional from "../common/additional";
import { UnwrapGrid } from "../../../layout/Tabs";

const consistencyOptions: IConsistencyOption[] = [
  { value: "fest", label: "fest" },
  { value: "flüssig", label: "flüssig" },
  { value: "pastös", label: "pastös" },
  { value: "staubförmig", label: "staubförmig" },
  { value: "trocken", label: "trocken" },
  { value: "zähflüssig", label: "zähflüssig" },
];

export interface IPickupOption {
  value: string;
  label: string;
}

export const pickupOptions: IPickupOption[] = [
  { value: "OPENING_HOURS", label: "Nur zu den Öffnungszeiten" },
  { value: "SELF_LOADING", label: "Selbstverladung jederzeit möglich" },
  { value: "IMMEDIATE_LOADING", label: "Sofortverladung durch Kunde" },
  { value: "INTERIM_STORAGE", label: "Zwischenlager möglich 2 Container" },
  { value: "EXCHANGE_CONTAINER", label: "Tauschcontainer oder Mulde" },
  { value: "SUCTION", label: "Nur im Saugauto möglich" },
  { value: "MISCELLANEOUS", label: "Sonstiges - im Kommentar eintragen" },
];

export interface IQuantityDeterminationOption {
  value: string;
  label: string;
}

export const quantityDeterminationOptions: IQuantityDeterminationOption[] = [
  { value: "AGREEMENT", label: "nach Vereinbarung" },
  { value: "ON_SITE", label: "Verwiegung vor Ort" },
  { value: "RECYCLER", label: "Verwiegung bei Verwerter" },
  { value: "FOREIGN", label: "Fremdwiegung nächstgelegene Möglichkeit" },
  { value: "CBM", label: "nach CBM" },
  { value: "LITER", label: "nach Liter" },
  { value: "ATRO", label: "Atrotonnen" },
];

export interface IQualityControlOption {
  value: string;
  label: string;
}

export const qualityControlOptions: IQualityControlOption[] = [
  { value: "AGREEMENT", label: "Analyse von Abfall beigelegt" },
  {
    value: "ON_SITE",
    label: "Bescheidauszug und Bezeichnung des Abfalls beigelegt",
  },
  { value: "RECYCLER", label: "noch unbekannte Einstufung des Abfalls" },
  { value: "FOREIGN", label: "Häufigkeit der Analysen pro Jahr bekannt" },
  { value: "CBM", label: "Starke Verunreinigung" },
  { value: "LITER", label: "Bisher Geruchsprobleme bestanden" },
  { value: "ATRO", label: "Grundabfall von belastenden Flächen z.B. Autobahn" },
];

interface IBiogeneAbfaelleProps extends IBaseProps {
  infoHandler: IInfoHandler;
}

interface IBiogeneAbfaelleState {
  consistency: IConsistencyOption | null;
  consistency_additional: string;
  dry_substance: string;

  analysis: IYesNoOption | null;
  pickup: IPickupOption | null;
  quantity_determination: IQuantityDeterminationOption | null;
  quality_control: IQualityControlOption | null;

  comment: string;
}

class BiogeneAbfaelle extends React.Component<
  IBiogeneAbfaelleProps,
  IBiogeneAbfaelleState
> {
  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {};

  constructor(props: IBiogeneAbfaelleProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo;

    this.state = {
      consistency: null,
      consistency_additional: "",
      dry_substance: "",
      analysis: null,

      pickup: null,
      quantity_determination: null,
      quality_control: null,

      comment: "",
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<IBiogeneAbfaelleProps>,
    nextContext: any
  ): void {
    nextProps.infoHandler.getInfo = this.getInfo;
  }

  private getInfo = () => {
    const descriptionImageInfo =
      this.descriptionImageInfoHandler.getInfo &&
      this.descriptionImageInfoHandler.getInfo();

    if (
      !descriptionImageInfo ||
      descriptionImageInfo.wasteDescription.isBlank() ||
      !this.state.consistency ||
      !this.state.analysis
    ) {
      console.log("getInfo validation failed", descriptionImageInfo);
      return null;
    }

    return {
      inputType: WasteInputType.BIOGEN,
      description: descriptionImageInfo
        ? descriptionImageInfo.wasteDescription
        : "",
      photoAttachmentId: descriptionImageInfo
        ? descriptionImageInfo.photoAttachmentId
        : "",

      consistency: this.state.consistency.value,
      consistencyAdditional: this.state.consistency_additional,
      drySubstance: this.state.dry_substance,
      isAnalyticsAvailable: this.state.analysis.value === YES,
      pickup: this.state.pickup ? this.state.pickup.value : null,
      quantityDetermination: this.state.quantity_determination
        ? this.state.quantity_determination.value
        : null,
      qualityControl: this.state.quality_control
        ? this.state.quality_control.value
        : null,

      comment: this.state.comment,
    };
  };

  private onConsistencyChanged = (o: IConsistencyOption) =>
    this.setState({ consistency: o });
  private onConsistencyAdditionalChanged = (e: any) =>
    this.setState({ consistency_additional: e.target.value });
  private onDrySubstanceChanged = (e: any) =>
    this.setState({ dry_substance: e.target.value });
  private onAnalysisChanged = (o: IYesNoOption) =>
    this.setState({ analysis: o });
  private onPickupChanged = (o: IPickupOption) => this.setState({ pickup: o });
  private onQuantityDeterminationChanged = (o: IQuantityDeterminationOption) =>
    this.setState({ quantity_determination: o });
  private onQualityControlChanged = (o: IQualityControlOption) =>
    this.setState({ quality_control: o });

  private onCommentChanged = (e: any) =>
    this.setState({ comment: e.target.value });

  private renderMaterialDescription = () => {
    const { t } = this.props;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={6}>
            <Field
              isRequired
              name="consistency"
              label={t(
                "tickets.create.fields.biogene_abfaelle.material_description.consistency"
              )}
            >
              {(props: any) => (
                <Select
                  options={consistencyOptions}
                  onChange={this.onConsistencyChanged}
                  value={this.state.consistency}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="consistency_additional"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.consistency_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onConsistencyAdditionalChanged}
                  value={this.state.consistency_additional}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="dry_substance"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.dry_substance"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onDrySubstanceChanged}
                  value={this.state.dry_substance}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              isRequired
              name="analysis"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.analysis"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onAnalysisChanged}
                  value={this.state.analysis}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="pickup"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.pickup"
              )}
            >
              {(props: any) => (
                <Select
                  options={pickupOptions}
                  onChange={this.onPickupChanged}
                  value={this.state.pickup}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="quantity_determination"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.quantity_determination"
              )}
            >
              {(props: any) => (
                <Select
                  options={quantityDeterminationOptions}
                  onChange={this.onQuantityDeterminationChanged}
                  value={this.state.quantity_determination}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="quality_control"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.quality_control"
              )}
            >
              {(props: any) => (
                <Select
                  options={qualityControlOptions}
                  onChange={this.onQualityControlChanged}
                  value={this.state.quality_control}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  };

  public render() {
    return (
      <BasicStructure
        infoHandler={this.descriptionImageInfoHandler}
        materialDescription={this.renderMaterialDescription()}
        additional={renderAdditional(
          this.props.t,
          this.onCommentChanged,
          this.state.comment
        )}
        imageRequired={false}
        descriptionRequired={true}
      />
    );
  }
}

export default withTranslation()(BiogeneAbfaelle);
