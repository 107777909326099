import * as React from "react";
import { IBaseProps } from "../../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import BasicStructure from "./basic-structure";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import { IYesNoOption, yesNoOptions } from "./deponierbare-abfaelle";
import { IInfoHandler, WasteInputType } from "../CreateTicketForm";
import { IDescriptionImageInfoHandler } from "./description-image";
import renderAdditional from "../common/additional";
import renderCompanyAndProcess from "../common/company-process";
import { Mixture } from "../../../../api/graphql/graphql-global-types";
import { UnwrapGrid } from "../../../layout/Tabs";
import UploadHint from "../common/upload-hint";

interface IKunststoffeProps extends IBaseProps {
  infoHandler: IInfoHandler;
}

interface IKunststoffeState {
  produced: string;
  process: string;
  originalUse: string;

  analysis: IYesNoOption | null;
  analysis_additional: string | null;
  detail: string;
  foreign_matter_exists: IYesNoOption | null;
  foreign_matter_exists_additional: string | null;
  is_licenced: IYesNoOption | null;
  is_licenced_additional: string | null;
  mixture: IMixtureOption | null;
  kind: string;
  fuellstoffe: IYesNoOption | null;
  fuellstoffe_additional: string | null;
  fiberglass: IYesNoOption | null;
  fiberglass_additional: string | null;
  flammhemmer: string;
  addtive: string;
  color: string;
  coating: IYesNoOption | null;

  comment: string;
}

export interface IMixtureOption {
  value: string;
  label: string;
}

export const mixtureOptions: IMixtureOption[] = [
  { value: "SORTENREIN", label: "Sortenrein" },
  { value: "GEMISCH", label: "Gemisch" },
];

class Kunststoffe extends React.Component<
  IKunststoffeProps,
  IKunststoffeState
> {
  private descriptionImageInfoHandler: IDescriptionImageInfoHandler = {};

  constructor(props: IKunststoffeProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo;

    this.state = {
      produced: "",
      process: "",
      originalUse: "",

      analysis: null,
      analysis_additional: null,
      detail: "",
      foreign_matter_exists: null,
      foreign_matter_exists_additional: null,
      is_licenced: null,
      is_licenced_additional: null,
      mixture: null,
      kind: "",
      fuellstoffe: null,
      fuellstoffe_additional: null,
      fiberglass: null,
      fiberglass_additional: null,
      flammhemmer: "",
      addtive: "",
      color: "",
      coating: null,

      comment: "",
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<IKunststoffeProps>,
    nextContext: any
  ): void {
    nextProps.infoHandler.getInfo = this.getInfo;
  }

  private getInfo = () => {
    const descriptionImageInfo =
      this.descriptionImageInfoHandler.getInfo &&
      this.descriptionImageInfoHandler.getInfo();

    if (
      !descriptionImageInfo ||
      descriptionImageInfo.wasteDescription.isBlank() ||
      !descriptionImageInfo.filecontent ||
      !descriptionImageInfo.photoAttachmentId ||
      this.state.process.isBlank() ||
      this.state.produced.isBlank() ||
      this.state.detail.isBlank() ||
      !this.state.is_licenced
    ) {
      console.log("getInfo validation failed", descriptionImageInfo);
      return null;
    }

    return {
      inputType: WasteInputType.PLASTIC,
      description: descriptionImageInfo
        ? descriptionImageInfo.wasteDescription
        : "",
      photoAttachmentId: descriptionImageInfo
        ? descriptionImageInfo.photoAttachmentId
        : "",

      production: this.state.produced,
      process: this.state.process,
      usage: this.state.originalUse,

      isAnalyticsAvailable: this.state.analysis
        ? this.state.analysis.value === "true"
        : null,
      isAnalyticsAvailableAdditional: this.state.analysis_additional,
      size: this.state.detail,
      hasForeignSubstance: this.state.foreign_matter_exists
        ? this.state.foreign_matter_exists.value === "true"
        : null,
      hasForeignSubstanceAdditional:
        this.state.foreign_matter_exists_additional,
      isLicensed: this.state.is_licenced.value === "true",
      isLicensedAdditional: this.state.is_licenced_additional,
      mixture: this.state.mixture
        ? this.state.mixture.value === Mixture.GEMISCH
          ? Mixture.GEMISCH
          : Mixture.SORTENREIN
        : null, // Sortenrein/Gemisch
      kind: this.state.kind, // Kunststoffart
      hasBulking: this.state.fuellstoffe
        ? this.state.fuellstoffe.value === "true"
        : null,
      hasBulkingAdditional: this.state.fuellstoffe_additional,
      hasFiber: this.state.fiberglass
        ? this.state.fiberglass.value === "true"
        : null,
      hasFiberAdditional: this.state.fiberglass_additional,
      flameRetardant: this.state.flammhemmer,
      additives: this.state.addtive,
      color: this.state.color,
      hasCoating: this.state.coating
        ? this.state.coating.value === "true"
        : null,
      comment: this.state.comment,
    };
  };

  private onProducedChanged = (e: any) =>
    this.setState({ produced: e.target.value });
  private onProcessChanged = (e: any) =>
    this.setState({ process: e.target.value });
  private onOriginalUseChanged = (e: any) =>
    this.setState({ originalUse: e.target.value });

  private onDetailChanged = (e: any) =>
    this.setState({ detail: e.target.value });
  private onAnalysisChanged = (o: IYesNoOption) =>
    this.setState({ analysis: o });
  private onAnalysisAdditionalChanged = (e: any) =>
    this.setState({ analysis_additional: e.target.value });
  private onKindChanged = (e: any) => this.setState({ kind: e.target.value });
  private onFlammhemmerChanged = (e: any) =>
    this.setState({ flammhemmer: e.target.value });
  private onAdditiveChanged = (e: any) =>
    this.setState({ addtive: e.target.value });
  private onColorChanged = (e: any) => this.setState({ color: e.target.value });
  private onCoatingChanged = (e: IYesNoOption) => this.setState({ coating: e });
  private onForeinMatterExistsChanged = (o: IYesNoOption) =>
    this.setState({ foreign_matter_exists: o });
  private onForeinMatterExistsAdditionalChanged = (e: any) =>
    this.setState({ foreign_matter_exists_additional: e.target.value });
  private onIsLicencedChanged = (o: IYesNoOption) =>
    this.setState({ is_licenced: o });
  private onIsLicencedAdditionalChanged = (e: any) =>
    this.setState({ is_licenced_additional: e.target.value });
  private onMixtureChanged = (o: IMixtureOption) =>
    this.setState({ mixture: o });
  private onFuellstoffeChanged = (o: IYesNoOption) =>
    this.setState({ fuellstoffe: o });
  private onFuellstoffeAdditionalChanged = (e: any) =>
    this.setState({ fuellstoffe_additional: e.target.value });
  private onFiberglassChanged = (o: IYesNoOption) =>
    this.setState({ fiberglass: o });
  private onFiberglassAdditionalChanged = (e: any) =>
    this.setState({ fiberglass_additional: e.target.value });
  private onCommentChanged = (e: any) =>
    this.setState({ comment: e.target.value });

  private renderMaterialDescription = () => {
    const { t } = this.props;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={6}>
            <Field
              name="analysis"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.analysis"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onAnalysisChanged}
                  value={this.state.analysis}
                  isSearchable={false}
                />
              )}
            </Field>
            <UploadHint analysis={this.state.analysis} />
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="analysis_additional"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.analysis_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onAnalysisAdditionalChanged}
                  value={this.state.analysis_additional}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={12}>
            <Field
              name="detail"
              label={t(
                "tickets.create.fields.deponierbare_abfaelle.material_description.detail"
              )}
              isRequired={true}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isCompact={false}
                  minimumRows={1}
                  onChange={this.onDetailChanged}
                  value={this.state.detail}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="foreign_matter_exists"
              label={t(
                "tickets.create.fields.kunststoffe.foreign_matter_exists"
              )}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onForeinMatterExistsChanged}
                  value={this.state.foreign_matter_exists}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="foreign_matter_exists_additional"
              label={t(
                "tickets.create.fields.kunststoffe.foreign_matter_exists_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onForeinMatterExistsAdditionalChanged}
                  value={this.state.foreign_matter_exists_additional}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="is_licenced"
              label={t("tickets.create.fields.kunststoffe.is_licenced")}
              isRequired={true}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onIsLicencedChanged}
                  value={this.state.is_licenced}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="is_licenced_additional"
              label={t(
                "tickets.create.fields.kunststoffe.is_licenced_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onIsLicencedAdditionalChanged}
                  value={this.state.is_licenced_additional}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="mixture"
              label={t("tickets.create.fields.kunststoffe.mixture")}
            >
              {(props: any) => (
                <Select
                  options={mixtureOptions}
                  onChange={this.onMixtureChanged}
                  value={this.state.mixture}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="kind"
              label={t("tickets.create.fields.kunststoffe.kind")}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onKindChanged}
                  value={this.state.kind}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="fuellstoffe"
              label={t("tickets.create.fields.kunststoffe.fuellstoffe")}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onFuellstoffeChanged}
                  value={this.state.fuellstoffe}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="fuellstoffe_additional"
              label={t(
                "tickets.create.fields.kunststoffe.fuellstoffe_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onFuellstoffeAdditionalChanged}
                  value={this.state.fuellstoffe_additional}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="fiberglass"
              label={t("tickets.create.fields.kunststoffe.fiberglass")}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onFiberglassChanged}
                  value={this.state.fiberglass}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="fiberglass_additional"
              label={t(
                "tickets.create.fields.kunststoffe.fiberglass_additional"
              )}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onFiberglassAdditionalChanged}
                  value={this.state.fiberglass_additional}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="flammhemmer"
              label={t("tickets.create.fields.kunststoffe.flammhemmer")}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onFlammhemmerChanged}
                  value={this.state.flammhemmer}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="addtive"
              label={t("tickets.create.fields.kunststoffe.addtive")}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onAdditiveChanged}
                  value={this.state.addtive}
                />
              )}
            </Field>
          </GridColumn>

          <GridColumn medium={6}>
            <Field
              name="color"
              label={t("tickets.create.fields.kunststoffe.color")}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <Textfield
                  {...fieldProps}
                  isCompact={false}
                  onChange={this.onColorChanged}
                  value={this.state.color}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <Field
              name="coating"
              label={t("tickets.create.fields.kunststoffe.coating")}
            >
              {(props: any) => (
                <Select
                  options={yesNoOptions}
                  onChange={this.onCoatingChanged}
                  value={this.state.coating}
                  isSearchable={false}
                />
              )}
            </Field>
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  };

  public render() {
    return (
      <BasicStructure
        infoHandler={this.descriptionImageInfoHandler}
        companyAndProcess={renderCompanyAndProcess(
          this.props.t,
          this.onProducedChanged,
          this.state.produced,
          this.onProcessChanged,
          this.state.process,
          this.onOriginalUseChanged,
          this.state.originalUse
        )}
        materialDescription={this.renderMaterialDescription()}
        additional={renderAdditional(
          this.props.t,
          this.onCommentChanged,
          this.state.comment
        )}
        imageRequired={true}
        descriptionRequired={true}
      />
    );
  }
}

export default withTranslation()(Kunststoffe);
