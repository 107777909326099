import * as React from "react";
import __ from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IBaseProps } from "../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import PageHeader from "@atlaskit/page-header";
import Tabs from "@atlaskit/tabs";
import { FieldTextStateless } from "@atlaskit/field-text";
import Textfield from "@atlaskit/textfield";
import { CardFrame } from "@atlaskit/media-ui";
import Kunststoffe from "./ssm-specific/kunststoffe";
import Metalle from "./ssm-specific/metalle";
import AltholzPapier from "./ssm-specific/altholz-papier";
import Verwertung from "./ssm-specific/verwertung";
import DeponierbareAbfaelle from "./ssm-specific/deponierbare-abfaelle";
import GefaehrlicheAbfaelle from "./ssm-specific/gefaehrliche-abfaelle";
import SiedlungsAbfaelle from "./ssm-specific/siedlungs-abfaelle";
import BiogeneAbfaelle from "./ssm-specific/biogene-abfaelle";
import EAG from "./ssm-specific/eag";
import Batterien from "./ssm-specific/batterien";
import Button, { ButtonGroup } from "@atlaskit/button";
import {
  DisposalWasteInput,
  HazardousWasteInput,
  PlasticWasteInput,
  MetalWasteInput,
  PaperWasteInput,
  UtilWasteInput,
  HouseWasteInput,
  BiogenWasteInput,
  EagWasteInput,
  BatteryWasteInput,
  WasteFrequency,
} from "../../../api/graphql/graphql-global-types";
import createTicketMutuation, {
  CreateTicketMutation,
} from "../../../api/graphql/mutations/CreateTicketMutation";
import { createTicketVariables } from "../../../api/graphql/mutations/types/createTicket";
import { toast } from "react-toastify";
import EditSelect, { IEditSelectOption } from "../tickets/fields/EditSelect";
import Label from "../tickets/fields/Label";
import { toSelectOption as oenormsToSelectOption } from "../../../api/graphql/fragments/Oenorm";
import { toSelectOption as materialsToSelectOption } from "../../../api/graphql/fragments/Material";
import {
  IOenormOption,
  IMaterialOption,
  IDivisionOption,
} from "../../../api/graphql/interfaces/OptionsInterfaces";
import { PartnerFragment as IPartner } from "../../../api/graphql/fragments/types/PartnerFragment";
import PartnerSelect from "../tickets/fields/PartnerSelect";
import mapSSMGroup from "./ssm-specific/map-ssm-groups";
import { IUnitOption, UNITS } from "../../../domain/models/units";
import {
  IWasteFrequencyOption,
  WASTE_FREQUENCY,
} from "../../../domain/models/waste-frequency";
import EditCheckbox from "../tickets/fields/EditCheckbox";
import { DistributionUserSelection } from "./DistributionUserSelection";
import { TabsWrapper, TabUnwrapGrid, TabWrapper } from "../../layout/Tabs";
import { Abilities } from "../../../domain/models/abilities";

const DEFAULT_MATERIAL_MAPPING = {};

export enum WasteInputType {
  DISPOSAL,
  PLASTIC,
  METAL,
  PAPER,
  VERWERTUNG,
  HAZARDOUS,
  SIEDLUNG,
  BIOGEN,
  EAG,
  BATTERY,
}

interface IWasteInputType {
  inputType: WasteInputType;
}

export interface IInfoHandler {
  getInfo?:
    | (() =>
        | (DisposalWasteInput & IWasteInputType)
        | (PlasticWasteInput & IWasteInputType)
        | (HazardousWasteInput & IWasteInputType)
        | (HouseWasteInput & IWasteInputType)
        | (BiogenWasteInput & IWasteInputType)
        | null)
    | undefined;
}

class AlternativePartner {
  customer?: string;
  name: string;
  industry: string;
  street: string;
  number: string;
  postalCode: string;
  city: string;
  contact?: string;
  location?: string;

  constructor() {
    this.name = "";
    this.industry = "";
    this.street = "";
    this.number = "";
    this.postalCode = "";
    this.city = "";
  }
}

interface ICreateTicketFormProps extends IBaseProps {
  oenorms: IOenormOption[];
  materials: IMaterialOption[];
  divisions: IDivisionOption[];
  history: any;
  hasAbility: (ability: string) => boolean;
}

interface ICreateTicketFormState {
  selectedUnit?: IUnitOption | null;
  selectedWasteFrequency?: IWasteFrequencyOption | null;
  selectedOenorm?: IOenormOption | null;
  selectedMaterial?: IMaterialOption | null;
  selectedDivision?: IDivisionOption | null;
  selectedPartner?: IPartner | null;
  matchingPartners: IPartner[];
  alternativePartner: AlternativePartner;
  selectedPartnerTab: number;
  wasteInfo?: string;
  quantity?: number;
  pack?: string;
  transport?: string;
  requestAssignment: boolean;
  selectedDistributionUser?: any;
}

class CreateTicketForm extends React.Component<
  ICreateTicketFormProps & RouteComponentProps<{}>,
  ICreateTicketFormState
> {
  private infoHandler: IInfoHandler = {};

  constructor(props: ICreateTicketFormProps & RouteComponentProps<{}>) {
    super(props);

    this.state = {
      alternativePartner: new AlternativePartner(),
      selectedPartnerTab: 0,
      matchingPartners: [],
      wasteInfo: "",
      requestAssignment: false,
    };
  }

  private selectUnit = (name: string, selected?: IEditSelectOption) => {
    this.setState({
      selectedUnit: selected
        ? Object.values(UNITS).find((o) => o.value === selected.value)
        : null,
    });
  };

  private selectWasteFrequency = (
    name: string,
    selected?: IEditSelectOption
  ) => {
    this.setState({
      selectedWasteFrequency: selected
        ? Object.values(WASTE_FREQUENCY).find((o) => o.value === selected.value)
        : null,
    });
  };

  private selectOenorm = (name: string, selected?: IEditSelectOption) => {
    const oenorm = selected
      ? this.props.oenorms.find((o) => o.value === selected.value)
      : null;
    const defaultMaterialNr = oenorm && DEFAULT_MATERIAL_MAPPING[oenorm.code];

    this.setState({
      selectedOenorm: oenorm,
      selectedMaterial: this.props.materials
        .filter((material) => material.matnr === defaultMaterialNr)
        .shift(),
    });
  };

  private selectMaterial = (name: string, selected?: IEditSelectOption) => {
    const material = selected
      ? this.props.materials.find((m) => m.value === selected.value)
      : null;

    const selectedDivision =
      material && material.prdha
        ? mapSSMGroup(material.prdha, this.props.divisions)
        : null;

    this.setState({
      selectedOenorm: material
        ? this.props.oenorms.find((o) => o.value === material.oenormId)
        : this.state.selectedOenorm,
      selectedMaterial: material,
      selectedDivision,
      wasteInfo: selectedDivision ? selectedDivision.label : undefined,
    });
  };

  private selectDistributionUser = (
    name: string,
    selected?: IEditSelectOption
  ) => {
    this.setState({
      selectedDistributionUser: selected
        ? { value: selected.value, label: selected.label }
        : null,
    });
  };

  private onAssignmentRequested = (name: string, selected: Boolean) => {
    this.setState({
      requestAssignment: selected.valueOf(),
      selectedMaterial: null,
      selectedOenorm: selected.valueOf()
        ? this.props.oenorms.find((o) => o.code === "99999")
        : null,
    });
  };

  private selectDivision = (d: IDivisionOption) => {
    this.infoHandler.getInfo = undefined;

    this.setState({
      selectedDivision: d,
      wasteInfo: d.label,
    });
  };

  private selectPartner = (p: IPartner | null) => {
    this.setState({
      selectedPartner: p,
    });
  };

  private onAlternativePartnerCustomerChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.customer = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerNameChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.name = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerIndustryChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.industry = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerStreetChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.street = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerNumberChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.number = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerPostalCodeChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.postalCode = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerCityChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.city = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerContactChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.contact = e.target.value;
    this.setState({ alternativePartner });
  };

  private onAlternativePartnerLocationChange = (e: any) => {
    const alternativePartner = this.state.alternativePartner;
    alternativePartner.location = e.target.value;
    this.setState({ alternativePartner });
  };

  private onQuantityChange = (e: any) => {
    this.setState({ quantity: parseFloat(e.target.value) });
  };

  private onPackChange = (e: any) => {
    this.setState({ pack: e.target.value });
  };

  private onTransportChange = (e: any) => {
    this.setState({ transport: e.target.value });
  };

  private partnerTabs = () => {
    const { t } = this.props;
    const { selectedPartner, alternativePartner, selectedPartnerTab } =
      this.state;

    return [
      {
        label: (
          <div>
            {t("tickets.create.fields.partner.title")}
            {selectedPartnerTab === 0 && (
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            )}
          </div>
        ),
        content: (
          <TabWrapper>
            <PartnerSelect
              value={selectedPartner}
              onChange={this.selectPartner}
              hideField
            />
          </TabWrapper>
        ),
      },
      {
        label: t("tickets.create.fields.partner.alternative.title"),
        content: (
          <TabWrapper>
            <TabUnwrapGrid>
              <Grid layout="max-width">
                <GridColumn medium={6}>
                  <FieldTextStateless
                    name="customer"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.customer"
                    )}
                    value={alternativePartner.customer}
                    onChange={this.onAlternativePartnerCustomerChange}
                    shouldFitContainer={true}
                    compact={false}
                  />
                </GridColumn>

                <GridColumn medium={6}>
                  <FieldTextStateless
                    name="name"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.name"
                    )}
                    required
                    value={alternativePartner.name}
                    onChange={this.onAlternativePartnerNameChange}
                    shouldFitContainer={true}
                    compact={false}
                  />
                </GridColumn>

                <GridColumn medium={6}>
                  <FieldTextStateless
                    name="industry"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.industry"
                    )}
                    required
                    value={alternativePartner.industry}
                    onChange={this.onAlternativePartnerIndustryChange}
                    shouldFitContainer={true}
                    compact={false}
                  />
                </GridColumn>

                <GridColumn medium={4}>
                  <FieldTextStateless
                    name="street"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.street"
                    )}
                    required
                    value={alternativePartner.street}
                    onChange={this.onAlternativePartnerStreetChange}
                    shouldFitContainer={true}
                    compact={false}
                  />
                </GridColumn>
                <GridColumn medium={2}>
                  <FieldTextStateless
                    name="number"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.number"
                    )}
                    required
                    value={alternativePartner.number}
                    onChange={this.onAlternativePartnerNumberChange}
                    shouldFitContainer={true}
                    compact={false}
                  />
                </GridColumn>

                <GridColumn medium={2}>
                  <FieldTextStateless
                    name="postal_code"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.postal_code"
                    )}
                    required
                    value={alternativePartner.postalCode}
                    onChange={this.onAlternativePartnerPostalCodeChange}
                    shouldFitContainer={true}
                    compact={false}
                  />
                </GridColumn>
                <GridColumn medium={4}>
                  <FieldTextStateless
                    name="city"
                    required
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.city"
                    )}
                    value={alternativePartner.city}
                    onChange={this.onAlternativePartnerCityChange}
                    compact={false}
                    shouldFitContainer={true}
                  />
                </GridColumn>

                <GridColumn medium={6}>
                  <FieldTextStateless
                    name="contact"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.contact"
                    )}
                    value={alternativePartner.contact}
                    onChange={this.onAlternativePartnerContactChange}
                    compact={false}
                    shouldFitContainer={true}
                  />
                </GridColumn>

                <GridColumn medium={6}>
                  <FieldTextStateless
                    name="location"
                    label={t(
                      "tickets.create.fields.partner.alternative.fields.location"
                    )}
                    value={alternativePartner.location}
                    onChange={this.onAlternativePartnerLocationChange}
                    compact={false}
                    shouldFitContainer={true}
                  />
                </GridColumn>
              </Grid>
            </TabUnwrapGrid>
          </TabWrapper>
        ),
      },
    ];
  };

  private onChangePartnerTab = (tab: any, index: number) => {
    const { selectedPartner, alternativePartner, selectedPartnerTab } =
      this.state;
    this.setState({
      selectedPartnerTab: index,
      selectedPartner:
        index === 1 && selectedPartnerTab === 0 ? null : selectedPartner,
      alternativePartner:
        index === 0 && selectedPartnerTab === 1
          ? new AlternativePartner()
          : alternativePartner,
    });
  };

  private getWastInfoComponent = (wasteInfo: string) => {
    switch (wasteInfo) {
      case "Kunststoffe":
        return <Kunststoffe infoHandler={this.infoHandler} />;
      case "Metalle":
        return <Metalle infoHandler={this.infoHandler} />;
      case "Altholz/-papier":
        return <AltholzPapier infoHandler={this.infoHandler} />;
      case "Sonstige Wertstoffe zur stofflichen Verwertung":
        return <Verwertung infoHandler={this.infoHandler} />;
      case "Gefährliche Abfälle":
        return <GefaehrlicheAbfaelle infoHandler={this.infoHandler} />;
      case "Deponierbare Abfälle":
        return <DeponierbareAbfaelle infoHandler={this.infoHandler} />;
      case "Siedlungsabfälle":
        return <SiedlungsAbfaelle infoHandler={this.infoHandler} />;
      case "Biogene Abfälle":
        return <BiogeneAbfaelle infoHandler={this.infoHandler} />;
      case "EAG":
        return <EAG infoHandler={this.infoHandler} />;
      case "Batterien":
        return <Batterien infoHandler={this.infoHandler} />;

      default:
        return null;
    }
  };

  private send = (
    event: any,
    mutation: (options: { variables: createTicketVariables }) => Promise<any>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const {
      selectedOenorm,
      selectedMaterial,
      selectedDivision,
      selectedPartner,
      selectedUnit,
      selectedWasteFrequency,
      alternativePartner,
      quantity,
      pack,
      transport,
    } = this.state;
    const wasteInfo = this.infoHandler.getInfo && this.infoHandler.getInfo();

    if (
      quantity === undefined ||
      !Number.isFinite(quantity) ||
      !selectedUnit ||
      !selectedWasteFrequency ||
      pack === undefined ||
      pack.length < 1
    ) {
      toast.error(this.props.t("check_input"));
      return;
    }

    const isPartnerValid = selectedPartner
      ? true
      : alternativePartner.city.isNotBlank() &&
        alternativePartner.postalCode.isNotBlank() &&
        alternativePartner.number.isNotBlank() &&
        alternativePartner.street.isNotBlank() &&
        alternativePartner.name.isNotBlank();

    if (!selectedOenorm || !selectedDivision || !isPartnerValid || !wasteInfo) {
      toast.error(this.props.t("check_input"));
      return;
    }

    const general = {
      oenormId: selectedOenorm.value,
      materialId: selectedMaterial ? selectedMaterial.value : null,
      divisionId: selectedDivision.value,
      quantity,
      unit: selectedUnit.value,
      frequency:
        selectedWasteFrequency.value === WasteFrequency.SINGLE
          ? WasteFrequency.SINGLE
          : selectedWasteFrequency.value === WasteFrequency.MONTHLY
          ? WasteFrequency.MONTHLY
          : selectedWasteFrequency.value === WasteFrequency.YEARLY
          ? WasteFrequency.YEARLY
          : WasteFrequency.CONTINUOUS,
      pack,
      transport,
      partnerId: selectedPartner ? selectedPartner.id : null,
      partnerCustomer: !selectedPartner ? alternativePartner.customer : null,
      partnerName: !selectedPartner ? alternativePartner.name : null,
      partnerIndustry: !selectedPartner ? alternativePartner.industry : null,
      partnerStreet: !selectedPartner ? alternativePartner.street : null,
      partnerNumber: !selectedPartner ? alternativePartner.number : null,
      partnerPostal: !selectedPartner ? alternativePartner.postalCode : null,
      partnerCity: !selectedPartner ? alternativePartner.city : null,
      partnerContact: !selectedPartner ? alternativePartner.contact : null,
      partnerLocation: !selectedPartner ? alternativePartner.location : null,
    };

    const { inputType, ...rest } = wasteInfo;

    mutation({
      variables: {
        general,
        disposalWaste:
          inputType === WasteInputType.DISPOSAL
            ? (rest as DisposalWasteInput)
            : null,
        plasticWaste:
          inputType === WasteInputType.PLASTIC
            ? (rest as PlasticWasteInput)
            : null,
        metalWaste:
          inputType === WasteInputType.METAL ? (rest as MetalWasteInput) : null,
        paperWaste:
          inputType === WasteInputType.PAPER ? (rest as PaperWasteInput) : null,
        utilWaste:
          inputType === WasteInputType.VERWERTUNG
            ? (rest as UtilWasteInput)
            : null,
        hazardousWaste:
          inputType === WasteInputType.HAZARDOUS
            ? (rest as HazardousWasteInput)
            : null,
        houseWaste:
          inputType === WasteInputType.SIEDLUNG
            ? (rest as HouseWasteInput)
            : null,
        biogenWaste:
          inputType === WasteInputType.BIOGEN
            ? (rest as BiogenWasteInput)
            : null,
        eagWaste:
          inputType === WasteInputType.EAG ? (rest as EagWasteInput) : null,
        batteryWaste:
          inputType === WasteInputType.BATTERY
            ? (rest as BatteryWasteInput)
            : null,
        distributionUserId: this.state.selectedDistributionUser?.value ?? null,
      },
    }).then((r) => {
      this.props.history.push(`/tickets/${__.get(r, "data.createTicket.id")}`);
    });
  };

  public render() {
    const { t, oenorms, materials, divisions, hasAbility } = this.props;
    const {
      selectedUnit,
      selectedWasteFrequency,
      selectedMaterial,
      selectedOenorm,
      selectedDivision,
      transport,
      quantity,
      pack,
    } = this.state;

    const isStoffstrom =
      hasAbility(Abilities.STOFFSTROM) ||
      hasAbility(Abilities.STOFFSTROM_MANAGER);

    return (
      <CreateTicketMutation mutation={createTicketMutuation}>
        {(mutation, status) => (
          <div>
            <PageHeader
              actions={
                <ButtonGroup>
                  <Button
                    appearance="primary"
                    type="submit"
                    onClick={(e: any) => this.send(e, mutation)}
                    isLoading={status.loading}
                    isDisabled={status.loading}
                  >
                    Erstellen
                  </Button>
                </ButtonGroup>
              }
            >
              {t("tickets.create.title")}
            </PageHeader>
            <TabUnwrapGrid>
              <Grid layout="max-width">
                <GridColumn medium={6}>
                  <CardFrame text={t("generic_waste_info")}>
                    <div style={{ margin: "0px 10px 10px 10px" }}>
                      <EditCheckbox
                        translation={"tickets.create.fields.request_assignment"}
                        name={"request_assignment"}
                        hideHelper={true}
                        onChange={this.onAssignmentRequested}
                        selected={this.state.requestAssignment}
                      />

                      {!this.state.requestAssignment && (
                        <>
                          <EditSelect
                            name="material"
                            translation="tickets.create.fields.material"
                            options={
                              materialsToSelectOption(
                                materials.filter(
                                  (m) =>
                                    !selectedOenorm ||
                                    m.oenormId === selectedOenorm.id
                                ),
                                oenorms
                              ) || []
                            }
                            onChange={this.selectMaterial}
                            selected={
                              selectedMaterial
                                ? {
                                    ...selectedMaterial,
                                    title: selectedMaterial.matnr,
                                    additional:
                                      selectedMaterial.title || "Unbekannt",
                                    subtitle: null,
                                  }
                                : null
                            }
                          />
                          <EditSelect
                            name="oenorm"
                            translation="tickets.create.fields.oenorm"
                            required
                            options={oenormsToSelectOption(oenorms)}
                            onChange={this.selectOenorm}
                            selected={
                              selectedOenorm
                                ? {
                                    ...selectedOenorm,
                                    title: `${selectedOenorm.code}${
                                      selectedOenorm.spezifikation
                                        ? ` ${selectedOenorm.spezifikation}`
                                        : ""
                                    }`,
                                    additional:
                                      selectedOenorm.title || "Unbekannt",
                                  }
                                : null
                            }
                          />
                        </>
                      )}

                      <Field
                        name="division"
                        label={t("tickets.create.fields.division.title")}
                        isRequired={true}
                      >
                        {(props: any) => (
                          <Select
                            isSearchable={true}
                            options={divisions}
                            placeholder={t(
                              "tickets.create.fields.division.placeholder"
                            )}
                            name="material"
                            styles={{
                              control: (styles: any) => ({
                                ...styles,
                                height: "53px",
                              }),
                              valueContainer: (styles: any) => ({
                                ...styles,
                                height: "50px",
                              }),
                            }}
                            onChange={this.selectDivision}
                            value={selectedDivision}
                          />
                        )}
                      </Field>

                      <TabUnwrapGrid>
                        <br />

                        {/* Split this in two grids because of iPad view (linebreak in labels) */}
                        <Grid layout="max-width">
                          <GridColumn medium={2}>
                            <Label
                              name="quantity"
                              translation="tickets.create.fields.quantity"
                              required
                            />
                          </GridColumn>
                          <GridColumn medium={2}>
                            <Label
                              name="unit"
                              translation="tickets.create.fields.unit"
                              required
                            />
                          </GridColumn>
                          <GridColumn medium={2}>
                            <Label
                              name="frequency"
                              translation="tickets.create.fields.frequency"
                              required
                            />
                          </GridColumn>
                        </Grid>
                        <Grid layout="max-width">
                          <GridColumn medium={2}>
                            <Textfield
                              name="quantity"
                              required
                              value={
                                quantity !== undefined &&
                                Number.isFinite(quantity)
                                  ? `${quantity}`
                                  : ""
                              }
                              onChange={this.onQuantityChange}
                            />
                          </GridColumn>
                          <GridColumn medium={2}>
                            <EditSelect
                              name="unit"
                              translation="tickets.create.fields.unit"
                              required
                              options={Object.values(UNITS)}
                              onChange={this.selectUnit}
                              selected={selectedUnit}
                              showField={false}
                            />
                          </GridColumn>
                          <GridColumn medium={2}>
                            <EditSelect
                              name="unit"
                              translation="tickets.create.fields.frequency"
                              required
                              options={Object.values(WASTE_FREQUENCY)}
                              onChange={this.selectWasteFrequency}
                              selected={selectedWasteFrequency}
                              showField={false}
                            />
                          </GridColumn>
                        </Grid>

                        <Grid layout="max-width">
                          <GridColumn medium={6}>
                            <FieldTextStateless
                              name="pack"
                              label={t("tickets.create.fields.pack.title")}
                              required
                              value={pack}
                              onChange={this.onPackChange}
                              shouldFitContainer={true}
                              compact={false}
                            />
                          </GridColumn>

                          <GridColumn medium={6}>
                            <FieldTextStateless
                              name="transport"
                              label={t("tickets.create.fields.transport.title")}
                              value={transport}
                              onChange={this.onTransportChange}
                              shouldFitContainer={true}
                              compact={false}
                            />
                          </GridColumn>
                        </Grid>
                      </TabUnwrapGrid>
                    </div>
                  </CardFrame>
                </GridColumn>

                <GridColumn medium={6}>
                  <CardFrame text={t("partner")}>
                    <div style={{ margin: "0px 10px 10px 10px" }}>
                      <TabsWrapper>
                        <Tabs
                          tabs={this.partnerTabs()}
                          selected={this.state.selectedPartnerTab}
                          onSelect={this.onChangePartnerTab}
                        />
                      </TabsWrapper>
                    </div>
                  </CardFrame>
                  {isStoffstrom && (
                    <React.Fragment>
                      <div
                        style={{
                          height: "16px",
                          display: "block",
                          width: "100%",
                        }}
                      ></div>
                      <DistributionUserSelection
                        onChange={this.selectDistributionUser}
                        selected={this.state.selectedDistributionUser}
                      />
                    </React.Fragment>
                  )}
                </GridColumn>

                {this.state.wasteInfo && (
                  <React.Fragment>
                    <div
                      style={{
                        height: "16px",
                        display: "block",
                        width: "100%",
                      }}
                    ></div>
                    <GridColumn medium={12}>
                      <CardFrame text={this.state.wasteInfo}>
                        <div style={{ margin: "0px 10px 10px 10px" }}>
                          {this.getWastInfoComponent(this.state.wasteInfo)}
                        </div>
                      </CardFrame>
                    </GridColumn>
                  </React.Fragment>
                )}
              </Grid>
            </TabUnwrapGrid>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "16px",
                marginBottom: "32px",
              }}
            >
              <Button
                appearance="primary"
                type="submit"
                onClick={(e: any) => this.send(e, mutation)}
                isLoading={status.loading}
                isDisabled={status.loading}
              >
                Erstellen
              </Button>
            </div>
          </div>
        )}
      </CreateTicketMutation>
    );
  }
}

export default withRouter(withTranslation()(CreateTicketForm));
