import * as React from "react";
import { withTranslation } from "react-i18next";
import { IBaseProps } from "../../../../utils/BaseProps";
import { Field, HelperMessage, ErrorMessage } from "@atlaskit/form";
import { Checkbox } from "@atlaskit/checkbox";

interface IEditCheckboxProps extends IBaseProps {
  name: string;
  translation: String;
  selected?: Boolean | null;
  onChange?: (name: string, selected: Boolean) => void;
  helper?: String;
  hideHelper?: Boolean;
  disabled?: Boolean;
}

export default withTranslation()((props: IEditCheckboxProps) => {
  const {
    t,
    name,
    translation,
    selected,
    onChange,
    helper: helperOverride,
    hideHelper,
    disabled,
  } = props;
  const helper = t(`${translation}.helper`);

  return (
    <Field label={t(`${translation}.title`)} name={name}>
      {({
        fieldProps: { id, ...rest },
        error,
      }: {
        fieldProps: any;
        error: any;
      }) => (
        <React.Fragment>
          <Checkbox
            name={name}
            isChecked={selected}
            disabled={!!disabled}
            onChange={(event: any) =>
              onChange && onChange(name, !!event.target.checked)
            }
            label={helper}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {helper && !hideHelper && <HelperMessage>{helper}</HelperMessage>}
          {helperOverride && <HelperMessage>{helperOverride}</HelperMessage>}
        </React.Fragment>
      )}
    </Field>
  );
});
