import Button, { ButtonGroup } from "@atlaskit/button";
import Form from "@atlaskit/form";
import ModalDialog, {
  ModalFooter,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { Ticket_ticket as TicketType } from "../../../../api/graphql/queries/types/Ticket";
import { IBaseProps } from "../../../../utils/BaseProps";
import cancelTicketMutuation, {
  CancelTicketMutation,
} from "../../../../api/graphql/mutations/CancelTicketMutation";
import { cancelTicketVariables } from "../../../../api/graphql/mutations/types/cancelTicket";

interface ICancelActionProps extends IBaseProps {
  ticket: TicketType;
  show: Boolean;
  onClose: () => any;
  onChange?: () => any;
}

class CancelAction extends React.Component<ICancelActionProps, any> {
  state: any;

  constructor(props: ICancelActionProps) {
    super(props);

    this.state = {};
  }

  handleFieldChange = (fieldname: string, value: any) => {
    this.setState({
      [fieldname]: value,
    });
  };

  generateFormSubmitHandler =
    (
      mutation: (options: { variables: cancelTicketVariables }) => Promise<any>
    ) =>
    (data: Object) => {
      const variables = {
        id: this.props.ticket.id,
      };

      mutation({ variables })
        .then(() => {
          this.props.onChange && this.props.onChange();
          this.handleClose();
        })
        .catch(() => alert("error"));
    };

  handleClose = () => this.props.onClose();

  public render() {
    const { t, ticket } = this.props;
    const footer = (props: any) => (
      <ModalFooter showKeyline={props.showKeyline}>
        <div style={{ flexGrow: 1 }} />
        <ButtonGroup>
          <Button onClick={this.handleClose}>
            {t("tickets.cancel.modal.actions.cancel.title")}
          </Button>
          <Button appearance="primary" type="submit">
            {t("tickets.cancel.modal.actions.submit.title")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    );

    return (
      <ModalTransition>
        {this.props.show && (
          <ModalDialog
            heading={t("tickets.cancel.modal.title", {
              ticket: `T#${ticket.number}`,
            })}
            width="small"
            onClose={this.handleClose}
            components={{
              Container: ({
                children,
                className,
              }: {
                children: any;
                className: any;
              }) => (
                <CancelTicketMutation mutation={cancelTicketMutuation}>
                  {(mutation) => (
                    <Form onSubmit={this.generateFormSubmitHandler(mutation)}>
                      {({ formProps }: { formProps: any }) => (
                        <form {...formProps} className={className}>
                          {children}
                        </form>
                      )}
                    </Form>
                  )}
                </CancelTicketMutation>
              ),
              Footer: footer,
            }}
          >
            <p>
              {t("tickets.cancel.modal.confirmation", {
                number: ticket.number,
              })}
            </p>
          </ModalDialog>
        )}
      </ModalTransition>
    );
  }
}

export default withTranslation()(CancelAction);
