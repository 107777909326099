import React, { useMemo } from "react";
import { useDistributionUsersQuery } from "../../../api/graphql/queries/distribution-users";
import { CardFrame } from "@atlaskit/media-ui";
import { useTranslation } from "react-i18next";
import EditSelect, { IEditSelectOption } from "../tickets/fields/EditSelect";

interface IDistributionUserSelectionProps {
  onChange: (name: string, selected?: IEditSelectOption) => void;
  selected: IEditSelectOption | null;
}

export const DistributionUserSelection: React.FunctionComponent<
  IDistributionUserSelectionProps
> = (props) => {
  const { t } = useTranslation();
  const { onChange, selected } = props;
  const { data: users } = useDistributionUsersQuery();

  const distUsers = useMemo(() => {
    if (users) {
      return users.distributionUsers.map((user) => ({
        value: user.id,
        label: user.name || user.email,
      }));
    }
    return [];
  }, [users]);

  return (
    <CardFrame text={t("tickets.create.fields.distribution_user.label")}>
      <div style={{ margin: "0px 10px 10px 10px" }}>
        <EditSelect
          name="distributionUser"
          translation="tickets.create.fields.distribution_user"
          options={distUsers}
          onChange={onChange}
          selected={selected}
        />
      </div>
    </CardFrame>
  );
};
