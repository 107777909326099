import * as React from "react";
import i18next from "i18next";
import { Grid, GridColumn } from "@atlaskit/page";
import { Field } from "@atlaskit/form";
import TextArea from "@atlaskit/textarea";
import { UnwrapGrid } from "../../../layout/Tabs";

export default (
  t: i18next.TFunction,
  onCommentChange: Function,
  comment: any
) => {
  return (
    <UnwrapGrid>
      <Grid layout="max-width">
        <GridColumn medium={12}>
          <Field
            name="comment"
            label={t("tickets.create.fields.additional.comment")}
          >
            {({ fieldProps }: { fieldProps: any }) => (
              <TextArea
                {...fieldProps}
                isCompact={false}
                minimumRows={5}
                onChange={onCommentChange}
                value={comment}
              />
            )}
          </Field>
        </GridColumn>
      </Grid>
    </UnwrapGrid>
  );
};
