import * as React from "react";
import { MutationFn } from "react-apollo";
import { IBaseProps } from "../../../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import { Grid, GridColumn } from "@atlaskit/page";
import TextArea from "@atlaskit/textarea";
import { Field } from "@atlaskit/form";
import Dropzone, { DropEvent } from "react-dropzone";
import styled from "styled-components";
import DocumentIcon from "@atlaskit/icon/glyph/document";
import uploadAttachmentMutuation, {
  UploadAttachmentMutation,
} from "../../../../api/graphql/mutations/UploadAttachmentMutation";
import {
  uploadAttachment,
  uploadAttachmentVariables,
} from "../../../../api/graphql/mutations/types/uploadAttachment";
import { UnwrapGrid } from "../../../layout/Tabs";
import { Constants } from "../../../../utils/constants";

const DropDiv = styled.div`
  min-height: 116px;
  align-items: center;
  background-color: #fafbfc;
  border-color: #dfe1e6;
  border-radius: 3px;
  border-style: solid;
  box-sizing: border-box;
  color: #a5adba;
  display: flex;
  flex: 1 0 auto;
  font-size: 14px;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  word-wrap: break-word;
  border-width: 2px;
  padding: 6px 6px;
`;

const FileSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface IDescriptionImageProps extends IBaseProps {
  infoHandler: IDescriptionImageInfoHandler;
  descriptionRequired: boolean;
  imageRequired: boolean;
}

interface IDescriptionImageState {
  wasteDescription: string;
  filename?: string | null;
  filecontent?: string | null;
  photoAttachmentId?: string;
}

export interface IDescriptionImageInfoHandler {
  getInfo?: (() => IDescriptionImageState) | undefined;
}

class DescriptionImage extends React.Component<
  IDescriptionImageProps,
  IDescriptionImageState
> {
  constructor(props: IDescriptionImageProps) {
    super(props);

    this.props.infoHandler.getInfo = this.getInfo;

    this.state = {
      wasteDescription: "",
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<IDescriptionImageProps>,
    nextContext: any
  ): void {
    nextProps.infoHandler.getInfo = this.getInfo;
  }

  private getInfo = () => this.state;

  private onWasteDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({
      wasteDescription: e.target.value,
    });
  };

  private onDropFile =
    (mutation: MutationFn<uploadAttachment, uploadAttachmentVariables>) =>
    (acceptedFiles: File[], rejectedFiles: any, event: DropEvent) => {
      if (
        !Array.isArray(acceptedFiles) ||
        acceptedFiles.length === 0 ||
        acceptedFiles[0] === null
      ) {
        return;
      }

      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader.onloadend = () => {
        mutation({
          variables: {
            title: file.name,
            base64: reader.result as string,
          },
        });

        this.setState({
          filename: file.name,
          filecontent: reader.result as string,
        });
      };

      reader.readAsDataURL(file);
    };

  handleAttachmentMutationComplete = (response: uploadAttachment) => {
    if (response.uploadAttachment) {
      this.setState({
        photoAttachmentId: response.uploadAttachment.id,
      });
      console.log(
        "handleAttachmentMutationComplete",
        response.uploadAttachment.id
      );
    } else {
      this.setState({
        filename: null,
        filecontent: null,
        photoAttachmentId: undefined,
      });
    }
  };

  handleAttachmentMutationError = () => {
    this.setState({
      filename: null,
      filecontent: null,
      photoAttachmentId: undefined,
    });
    console.log("handleAttachmentMutationError");
  };

  public render() {
    const { t } = this.props;
    const { wasteDescription, filename, filecontent } = this.state;

    return (
      <UnwrapGrid>
        <Grid layout="max-width">
          <GridColumn medium={6}>
            <Field
              name="quality"
              label={t("tickets.create.fields.waste_description.title")}
              isRequired={this.props.descriptionRequired}
            >
              {({ fieldProps }: { fieldProps: any }) => (
                <TextArea
                  {...fieldProps}
                  isRequired={this.props.descriptionRequired}
                  minimumRows={5}
                  onChange={this.onWasteDescriptionChange}
                  value={wasteDescription}
                  isCompact={false}
                />
              )}
            </Field>
          </GridColumn>
          <GridColumn medium={6}>
            <UploadAttachmentMutation
              mutation={uploadAttachmentMutuation}
              onCompleted={this.handleAttachmentMutationComplete}
              onError={this.handleAttachmentMutationError}
            >
              {(mutation) => (
                <Field
                  name="image"
                  label={t("tickets.create.fields.image.title")}
                  isRequired={this.props.imageRequired}
                >
                  {(props: any) => (
                    <>
                      <Dropzone
                        multiple={false}
                        accept=".jpg, .jpeg, image/jpeg, image/jpg, .png, image/png, .pdf, .JPG, .JPEG, .PNG, .PDF, application/pdf, .docx, .DOCX, .doc, .DOC, .XLS, .xls, .XLSX, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxSize={Constants.MAX_FILE_SIZE_UPLOAD}
                        onDrop={this.onDropFile(mutation)}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragReject,
                        }: {
                          getRootProps: any;
                          getInputProps: any;
                          isDragActive: boolean;
                          isDragReject: boolean;
                        }) => (
                          <DropDiv {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragReject ? (
                              <p>{t(`tickets.create.fields.image.reject`)}</p>
                            ) : isDragActive ? (
                              <p>{t(`tickets.create.fields.image.active`)}</p>
                            ) : filename && filecontent ? (
                              <FileSelected>
                                <DocumentIcon label={""} size={"large"} />{" "}
                                <div>{filename}</div>
                              </FileSelected>
                            ) : (
                              <p>{t(`tickets.create.fields.image.dropzone`)}</p>
                            )}
                          </DropDiv>
                        )}
                      </Dropzone>
                      <div
                        style={{
                          marginTop: "1em",
                          textAlign: "center",
                          fontSize: "11px",
                        }}
                      >
                        {t("tickets.create.upload_hint")}
                      </div>
                    </>
                  )}
                </Field>
              )}
            </UploadAttachmentMutation>
          </GridColumn>
        </Grid>
      </UnwrapGrid>
    );
  }
}

export default withTranslation()(DescriptionImage);
