import Button, { ButtonGroup } from "@atlaskit/button";
import Form, { Field } from "@atlaskit/form";
import { CardFrame } from "@atlaskit/media-ui";
import ModalDialog, {
  ModalFooter,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Grid, GridColumn } from "@atlaskit/page";
import EditorRedoIcon from "@atlaskit/icon/glyph/editor/redo";
import * as React from "react";
import __ from "lodash";
import { withTranslation, useTranslation } from "react-i18next";
import styled from "styled-components";
import { toSelectOptionWithMaterial as materialsToSelectOption } from "../../../../api/graphql/fragments/Material";
import { toSelectOption as oenormsToSelectOption } from "../../../../api/graphql/fragments/Oenorm";
import loadMetaData, {
  MetaDataQuery,
} from "../../../../api/graphql/queries/loadMetaDataPks";
import {
  Ticket_plants as PlantType,
  Ticket_ticket as TicketType,
} from "../../../../api/graphql/queries/types/Ticket";
import {
  MetaData_oenorms as OenormType,
  MetaData_materials as MaterialType,
} from "../../../../api/graphql/queries/types/MetaData";
import { IBaseProps } from "../../../../utils/BaseProps";
import CardFrameContent from "../../../partials/CardFrameContent";
import EditSelect, { IEditSelectOption } from "../fields/EditSelect";
import { formatDisposalPlantOption } from "../fields/ShowPlant";
import EditNumber from "../fields/EditNumber";
import documentUtilizationMutuation, {
  DocumentUtilizationMutation,
} from "../../../../api/graphql/mutations/DocumentUtilizationMutation";
import { documentUtilizationVariables } from "../../../../api/graphql/mutations/types/documentUtilization";
import { TRANSPORTATION_ENUM } from "../../../../api/graphql/fragments/FullTicket";
import findRecommendationsQuery, {
  FindRecommendationsQuery,
} from "../../../../api/graphql/queries/findRecommendations";
import PageLoadingIndicator from "../../../partials/PageLoadingIndicator";
import moment from "moment";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import FieldValue from "../partials/FieldValue";
import Lozenge from "@atlaskit/lozenge";
import { FindRecommendations_findRecommendations } from "../../../../api/graphql/queries/types/findRecommendations";
import "moment/locale/de";
import { UNITS } from "../../../../domain/models/units";
import { FACILITY_GROUPS } from "../../../../api/facility-groups";
import EditTextArea from "../fields/EditTextArea";
import EditCheckbox from "../fields/EditCheckbox";
import Highlight from "../partials/Highlight";
import { NotPossibleDisposalReason } from "../../../../api/graphql/graphql-global-types";
import { UnwrapGrid } from "../../../layout/Tabs";

const UNITS_OPTIONS = Object.values(UNITS).map((unit) => ({
  ...unit,
  label: `€/${unit.label}`,
}));

const NOT_POSSIBLE_DISPOSABLE_REASON_OPTIONS = Object.values(
  NotPossibleDisposalReason
).map((reason) => ({ value: reason }));

interface IUtilizationActionProps extends IBaseProps {
  ticket: TicketType;
  plants: Array<PlantType>;
  show: Boolean;
  onClose: () => any;
  onChange?: () => any;
}

interface IUtilizationActionState {
  notPossibleDisposal: boolean;
  notPossibleDisposalReason?: IEditSelectOption;
  notPossibleDisposalNotes?: string;

  [rest: string]: any;
}

const Spacer = styled.div`
  display: flex-item;
  height: 1px;
  min-width: 100%;
  margin-bottom: 15px;
`;

const PksFrame = styled.div`
  display: flex;
`;

interface IRecommendationCardProps {
  recommendation: FindRecommendations_findRecommendations | null;
  onSelectRecommendation: (
    recommendation: FindRecommendations_findRecommendations
  ) => void;
}

const DUMMY_PLANT = {
  title: "",
  description: "Standort",
};

const RecommendationCard = (props: IRecommendationCardProps) => {
  const { recommendation } = props;
  const { t } = useTranslation();
  const formattedPlant = __.get(recommendation, "plant")
    ? formatDisposalPlantOption(__.get(recommendation, "plant") as any) || "-"
    : DUMMY_PLANT;
  const createdAt = moment(__.get(recommendation, "createdAt"), "YYYY-MM-DD");

  return (
    <React.Fragment>
      <CardFrame text={createdAt.fromNow()}>
        <CardFrameContent
          isHoverable
          onClick={() =>
            recommendation
              ? props.onSelectRecommendation(recommendation)
              : undefined
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ minWidth: "0" }}>
              {recommendation?.notPossibleDisposal && (
                <>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t(
                      `tickets.utilization.modal.section.disposal_possibility.reasons.${recommendation.notPossibleDisposalReason}`
                    )}
                  </div>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <i>{recommendation.notPossibleDisposalNote}</i>
                  </div>
                </>
              )}
              {!recommendation?.notPossibleDisposal && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <FieldValue>
                    <Lozenge>{__.get(recommendation, "costs")}€</Lozenge>
                  </FieldValue>
                </div>
              )}
              <FieldValue>{__.get(formattedPlant, "title")}</FieldValue>
              <FieldValue style={{ fontSize: "12px", color: "darkgray" }}>
                {__.get(formattedPlant, "description")}
              </FieldValue>
            </div>
            <div>
              <a
                href={`/tickets/${__.get(recommendation, "ticket.id")}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button>
                  <WatchIcon
                    size={"small"}
                    label={t("tickets.detail.sections.ticket.link.title")}
                  />
                </Button>
              </a>
            </div>
          </div>
        </CardFrameContent>
      </CardFrame>
      <Spacer />
    </React.Fragment>
  );
};

class UtilizationAction extends React.Component<IUtilizationActionProps, any> {
  state: IUtilizationActionState;

  constructor(props: IUtilizationActionProps) {
    super(props);

    this.state = {
      notPossibleDisposal: false,
    };
  }

  handleOenormChange = (fieldname: string, value: any) => {
    console.log("handleOenormChange", fieldname, value);
    this.setState({
      [fieldname]: value,
      material: null,
    });
  };

  handleMaterialChange =
    (oenorms: Array<OenormType>, materials: Array<MaterialType>) =>
    (fieldname: string, value: IEditSelectOption | undefined) => {
      const material = materials
        .filter((material) => value && material.id === value.value)
        .shift();
      console.log(material);
      this.setState({
        [fieldname]: value,
        oenorm:
          oenormsToSelectOption(
            oenorms.filter(
              (oenorm) => material && oenorm.id === material.oenormId
            )
          ).shift() || this.state.oenorm,
      });
    };

  handleMaterialRemarkChanged = (e: any) =>
    this.setState({ materialRemark: e.target.value });

  handleSelectUnit = (name: string, selected?: IEditSelectOption) => {
    this.setState({
      selectedUnit: selected
        ? UNITS_OPTIONS.find((o) => o.value === selected.value)
        : null,
    });
  };

  handleFieldChange = (fieldname: string, value: any) => {
    var dependencies: {
      compensation?: number | null;
      costs?: number | null;
      standort?: any | null;
    } = {};

    switch (fieldname) {
      case "costs":
        dependencies.compensation = null;
        break;
      case "compensation":
        dependencies.costs = null;
        break;
      case "streckenabholung":
        if (value) {
          dependencies.standort = null;
        }
        break;
    }

    this.setState({
      ...dependencies,
      [fieldname]: value,
    });
  };

  generateFormSubmitHandler =
    (
      mutation: (options: {
        variables: documentUtilizationVariables;
      }) => Promise<any>
    ) =>
    (data: Object) => {
      document.activeElement && (document.activeElement as HTMLElement).blur();
      setTimeout(() => {
        const variables = {
          ticketId: this.props.ticket.id,
          ...__.pick(this.state, [
            "costs",
            "compensation",
            "logisticCosts",
            "materialRemark",
            "notPossibleDisposal",
            "notPossibleDisposalNote",
          ]),
          streckenabholung:
            !this.state.notPossibleDisposal && !this.state.standort,
          selectedUnit: __.get(this.state, "selectedUnit.value"),
          standort: __.get(this.state, "standort.value"),
          transportation: __.get(this.state, "transportation.value"),
          plantId: __.get(this.state, "plant.value"),
          oenormId: __.get(this.state, "oenorm.value"),
          materialId: __.get(this.state, "material.value"),
          notPossibleDisposalReason: __.get(
            this.state,
            "notPossibleDisposalReason.value"
          ),
        };

        console.log("variables", variables);
        console.log("this.state", this.state);

        mutation({ variables })
          .then(() => {
            this.props.onChange && this.props.onChange();
            this.handleClose();
          })
          .catch(() => alert("error"));
      }, 0);
    };

  handleClose = () => this.props.onClose();

  handleSelectRecommendation =
    (plants: Array<PlantType>) =>
    (recommendation: FindRecommendations_findRecommendations) => {
      const plantId = recommendation.plant
        ? recommendation.plant.id
        : this.state.plantId;
      const plant = plants
        .filter((plant: PlantType) => plant.id === plantId)
        .shift();
      this.setState({
        costs: recommendation.costs,
        plantId,
        plant: plant ? formatDisposalPlantOption(plant) : null,
        notPossibleDisposal: recommendation.notPossibleDisposal,
        notPossibleDisposalNote: recommendation.notPossibleDisposalNote,
      });
      this.handleSelectNotPossibleDisposalReason(
        recommendation.notPossibleDisposalReason
      );
    };

  handleNotPossibleDisposalChange = (fieldname: string, value: Boolean) => {
    this.setState({
      compensation: null,
      costs: null,
      standort: null,
      unit: null,
      pricelist: null,
      selectedUnit: null,
      plant: null,
      logisticCosts: null,
      notPossibleDisposal: value,
    });
  };

  handleSelectNotPossibleDisposalReason = (
    newValue?: NotPossibleDisposalReason | null
  ) => {
    const item = NOT_POSSIBLE_DISPOSABLE_REASON_OPTIONS.find(
      (o) => o.value === newValue
    );
    this.setState({
      notPossibleDisposalReason: item
        ? {
            ...item,
            label: this.props.t(
              `tickets.utilization.modal.section.disposal_possibility.reasons.${item.value}`
            ),
          }
        : null,
    });
  };

  public render() {
    const { t, ticket } = this.props;
    const { notPossibleDisposal } = this.state;

    const recommendationQueryVariables = {
      plantId: __.get(this.state, "plant.value"),
      oenormId:
        __.get(this.state, "oenorm.value") ||
        __.get(ticket, "general.oenorm.id"),
      materialId:
        __.get(this.state, "material.value") ||
        __.get(ticket, "general.material.id"),
      divisionId: __.get(ticket, "general.division.id"),
    };

    const footer = (props: any) => (
      <ModalFooter showKeyline={props.showKeyline}>
        <div style={{ flexGrow: 1 }} />
        <ButtonGroup>
          <Button onClick={this.handleClose}>
            {t("tickets.utilization.modal.actions.cancel.title")}
          </Button>
          <Button appearance="primary" type="submit">
            {t("tickets.utilization.modal.actions.save.title")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    );

    const filterMaterials = (materials: Array<MaterialType>) =>
      (materials || []).filter(
        (material) =>
          !this.state.oenorm ||
          material.oenormId === __.get(this.state, "oenorm.value")
      );

    const pks =
      __.get(this.state, "material.material.pks") ||
      __.get(ticket, "general.material.pks");

    const notPossibleDisposalReasonOptions =
      NOT_POSSIBLE_DISPOSABLE_REASON_OPTIONS.map((reason) => ({
        ...reason,
        label: t(
          `tickets.utilization.modal.section.disposal_possibility.reasons.${reason.value}`
        ),
      }));

    const disableMonetarySection = notPossibleDisposal;
    const requireMonetarySection = !disableMonetarySection;

    const disableLogisticsSection = notPossibleDisposal;
    const requireLogisticsSection = !disableLogisticsSection;

    return (
      <ModalTransition>
        <MetaDataQuery query={loadMetaData} fetchPolicy={"cache-first"}>
          {({ data }) =>
            this.props.show && (
              <ModalDialog
                heading={t("tickets.utilization.modal.title", {
                  ticket: `T#${ticket.number}`,
                })}
                width="xx-large"
                height="95%"
                onClose={this.handleClose}
                components={{
                  Container: ({
                    children,
                    className,
                  }: {
                    children: any;
                    className: any;
                  }) => (
                    <DocumentUtilizationMutation
                      mutation={documentUtilizationMutuation}
                    >
                      {(mutation) => (
                        <Form
                          onSubmit={this.generateFormSubmitHandler(mutation)}
                        >
                          {({ formProps }: { formProps: any }) => (
                            <form {...formProps} className={className}>
                              {children}
                            </form>
                          )}
                        </Form>
                      )}
                    </DocumentUtilizationMutation>
                  ),
                  Footer: footer,
                }}
              >
                <Grid layout="max-width">
                  <GridColumn medium={8}>
                    <GridColumn medium={12}>
                      <CardFrame
                        text={t(
                          "tickets.utilization.modal.section.actual.title"
                        )}
                      >
                        <CardFrameContent>
                          <UnwrapGrid>
                            <Grid layout="max-width">
                              <GridColumn medium={6}>
                                <Field
                                  name="oenorm"
                                  label={t(
                                    "tickets.utilization.modal.section.actual.fields.oenorm.title"
                                  )}
                                  helperText={t(
                                    "tickets.utilization.modal.section.actual.fields.oenorm.helper"
                                  )}
                                >
                                  {(props: any) => (
                                    <FieldValue>
                                      <Highlight>
                                        {__.get(ticket, "general.oenorm.code")}
                                      </Highlight>{" "}
                                      {__.get(
                                        ticket,
                                        "general.oenorm.spezifikation"
                                      ) && (
                                        <span>
                                          {__.get(
                                            ticket,
                                            "general.oenorm.spezifikation"
                                          )}
                                        </span>
                                      )}{" "}
                                      - {__.get(ticket, "general.oenorm.title")}
                                    </FieldValue>
                                  )}
                                </Field>
                              </GridColumn>
                              <GridColumn medium={6}>
                                <Field
                                  name="material"
                                  label={t(
                                    "tickets.utilization.modal.section.actual.fields.material.title"
                                  )}
                                  helperText={t(
                                    "tickets.utilization.modal.section.actual.fields.material.helper"
                                  )}
                                >
                                  {(props: any) =>
                                    __.get(ticket, "general.material") ? (
                                      <FieldValue>
                                        <Highlight>
                                          {__.get(
                                            ticket,
                                            "general.material.matnr"
                                          )}
                                        </Highlight>{" "}
                                        -{" "}
                                        {__.get(
                                          ticket,
                                          "general.material.title",
                                          <i>
                                            {t(
                                              "tickets.utilization.modal.section.actual.fields.material.empty"
                                            )}
                                          </i>
                                        )}
                                      </FieldValue>
                                    ) : (
                                      <div> - </div>
                                    )
                                  }
                                </Field>
                              </GridColumn>
                            </Grid>
                          </UnwrapGrid>
                        </CardFrameContent>
                      </CardFrame>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Spacer />
                    </GridColumn>
                    <GridColumn medium={12}>
                      <CardFrame
                        text={t(
                          "tickets.utilization.modal.section.adjustments.title"
                        )}
                      >
                        <CardFrameContent>
                          <UnwrapGrid>
                            <Grid layout="max-width">
                              <GridColumn medium={4}>
                                <EditSelect
                                  name="oenorm"
                                  translation="tickets.utilization.modal.section.adjustments.fields.oenorm"
                                  options={
                                    (data &&
                                      data.oenorms &&
                                      oenormsToSelectOption(data.oenorms)) ||
                                    []
                                  }
                                  onChange={this.handleOenormChange}
                                  selected={this.state.oenorm}
                                />
                              </GridColumn>
                              <GridColumn medium={4}>
                                <EditSelect
                                  name="material"
                                  translation="tickets.utilization.modal.section.adjustments.fields.material"
                                  options={
                                    (data &&
                                      data.materials &&
                                      data.oenorms &&
                                      materialsToSelectOption(
                                        filterMaterials(data.materials),
                                        data.oenorms
                                      )) ||
                                    []
                                  }
                                  onChange={this.handleMaterialChange(
                                    (data && data.oenorms) || [],
                                    (data && data.materials) || []
                                  )}
                                  selected={this.state.material}
                                />
                              </GridColumn>
                              <GridColumn medium={4}>
                                <EditTextArea
                                  name="materialRemark"
                                  translation="tickets.utilization.modal.section.adjustments.fields.material_remark"
                                  onChange={this.handleFieldChange}
                                  value={this.state.materialRemark}
                                />
                              </GridColumn>
                            </Grid>
                          </UnwrapGrid>
                        </CardFrameContent>
                      </CardFrame>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Spacer />
                    </GridColumn>
                    {/* Disposal Possibility */}
                    <GridColumn medium={12}>
                      <CardFrame
                        text={t(
                          "tickets.utilization.modal.section.disposal_possibility.title"
                        )}
                      >
                        <CardFrameContent>
                          <UnwrapGrid>
                            <Grid layout="max-width">
                              <GridColumn medium={12}>
                                <EditCheckbox
                                  name="notPossibleDisposal"
                                  translation="tickets.utilization.modal.section.disposal_possibility.fields.not_possible_disposal"
                                  onChange={
                                    this.handleNotPossibleDisposalChange
                                  }
                                  selected={this.state.notPossibleDisposal}
                                />
                              </GridColumn>
                              {this.state.notPossibleDisposal && (
                                <>
                                  <GridColumn medium={6}>
                                    <EditSelect
                                      name="notPossibleDisposalReason"
                                      translation="tickets.utilization.modal.section.disposal_possibility.fields.not_possible_disposal_reason"
                                      required
                                      options={notPossibleDisposalReasonOptions}
                                      onChange={(name, selected) =>
                                        this.handleSelectNotPossibleDisposalReason(
                                          selected?.value
                                        )
                                      }
                                      selected={
                                        this.state.notPossibleDisposalReason
                                      }
                                    />
                                  </GridColumn>
                                  <GridColumn medium={6}>
                                    <EditTextArea
                                      name="notPossibleDisposalNote"
                                      translation="tickets.utilization.modal.section.disposal_possibility.fields.not_possible_disposal_note"
                                      onChange={this.handleFieldChange}
                                      value={this.state.notPossibleDisposalNote}
                                    />
                                  </GridColumn>
                                </>
                              )}
                            </Grid>
                          </UnwrapGrid>
                        </CardFrameContent>
                      </CardFrame>
                    </GridColumn>
                    <GridColumn medium={12}>
                      <Spacer />
                    </GridColumn>
                    {/* Monetary Information */}
                    {
                      <GridColumn medium={12}>
                        <div
                          style={
                            disableMonetarySection
                              ? {
                                  filter: "brightness(90%)",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          <CardFrame
                            text={t(
                              "tickets.utilization.modal.section.monetary.title"
                            )}
                          >
                            <CardFrameContent>
                              <UnwrapGrid>
                                <Grid layout="max-width">
                                  <GridColumn medium={3}>
                                    <Field
                                      name="pks"
                                      label={t(
                                        "tickets.detail.sections.material.pks.title"
                                      )}
                                      helperText={t(
                                        "tickets.detail.sections.material.pks.helper"
                                      )}
                                    >
                                      {(props: any) => (
                                        <FieldValue>
                                          {!disableMonetarySection && pks ? (
                                            <PksFrame>
                                              <div>
                                                {__.get(pks, "ktext")}
                                                {__.get(pks, "cost") && (
                                                  <>
                                                    <br />
                                                    <em>Kosten:</em>
                                                    <Highlight>
                                                      {`${
                                                        __.get(pks, "cost") ||
                                                        ""
                                                      }`.replace(".", ",")}{" "}
                                                      €
                                                    </Highlight>
                                                  </>
                                                )}
                                                {__.get(
                                                  pks,
                                                  "compensation"
                                                ) && (
                                                  <>
                                                    <br />
                                                    <em>Vergütung:</em>
                                                    <Highlight>
                                                      {`${
                                                        __.get(
                                                          pks,
                                                          "compensation"
                                                        ) || ""
                                                      }`.replace(".", ",")}{" "}
                                                      €
                                                    </Highlight>
                                                  </>
                                                )}
                                              </div>
                                              {__.get(pks, "cost") &&
                                                `${
                                                  __.get(pks, "cost") || ""
                                                }`.replace(".", ",") !==
                                                  `${this.state.costs}`.replace(
                                                    ".",
                                                    ","
                                                  ) && (
                                                  <div
                                                    style={{
                                                      paddingLeft: "20px",
                                                    }}
                                                  >
                                                    <Button
                                                      iconBefore={
                                                        <EditorRedoIcon
                                                          label="Übernehmen"
                                                          size="medium"
                                                        />
                                                      }
                                                      onClick={() =>
                                                        this.setState({
                                                          costs: parseFloat(
                                                            `${
                                                              __.get(
                                                                pks,
                                                                "cost"
                                                              ) || ""
                                                            }`.replace(",", ".")
                                                          ),
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                )}
                                            </PksFrame>
                                          ) : (
                                            <div>-</div>
                                          )}
                                        </FieldValue>
                                      )}
                                    </Field>
                                  </GridColumn>
                                  <GridColumn medium={2}>
                                    <EditNumber
                                      disabled={disableMonetarySection}
                                      required={
                                        requireMonetarySection &&
                                        !this.state.compensation &&
                                        this.state.compensation !== 0
                                      }
                                      name="costs"
                                      translation="tickets.utilization.modal.section.monetary.fields.costs"
                                      onChange={this.handleFieldChange}
                                      value={this.state.costs}
                                      allowZero
                                    />
                                  </GridColumn>
                                  <GridColumn medium={1}>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "3.5em",
                                        fontSize: "0.9em",
                                        color: "#6B778C",
                                      }}
                                    >
                                      oder
                                    </div>
                                  </GridColumn>
                                  <GridColumn medium={2}>
                                    <EditNumber
                                      disabled={disableMonetarySection}
                                      required={
                                        requireMonetarySection &&
                                        !this.state.costs &&
                                        this.state.costs !== 0
                                      }
                                      name="compensation"
                                      translation="tickets.utilization.modal.section.monetary.fields.compensation"
                                      onChange={this.handleFieldChange}
                                      value={this.state.compensation}
                                      allowZero
                                    />
                                  </GridColumn>
                                  <GridColumn medium={1}>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "3.5em",
                                        fontSize: "0.9em",
                                        color: "#6B778C",
                                      }}
                                    >
                                      in
                                    </div>
                                  </GridColumn>
                                  <GridColumn medium={3}>
                                    <EditSelect
                                      disabled={disableMonetarySection}
                                      name="unit"
                                      translation="tickets.utilization.modal.section.monetary.fields.unit"
                                      required={requireMonetarySection}
                                      options={UNITS_OPTIONS}
                                      onChange={this.handleSelectUnit}
                                      selected={this.state.selectedUnit}
                                    />
                                  </GridColumn>
                                  {(this.state.costs === 0 ||
                                    this.state.compensation === 0) && (
                                    <>
                                      <GridColumn medium={3}></GridColumn>
                                      <GridColumn medium={5}>
                                        <b>Hinweis:</b> Preis lt. Preisliste
                                        verwenden
                                      </GridColumn>
                                      <GridColumn medium={4}></GridColumn>
                                    </>
                                  )}
                                  <GridColumn medium={12}>
                                    <EditSelect
                                      disabled={disableMonetarySection}
                                      name="transportation"
                                      translation="tickets.utilization.modal.section.logistics.fields.transportation"
                                      options={Object.values(
                                        TRANSPORTATION_ENUM
                                      )}
                                      required={requireMonetarySection}
                                      onChange={this.handleFieldChange}
                                      selected={this.state.transportation}
                                      clearable
                                    />
                                  </GridColumn>
                                </Grid>
                              </UnwrapGrid>
                            </CardFrameContent>
                          </CardFrame>
                        </div>
                      </GridColumn>
                    }
                    <GridColumn medium={12}>
                      <Spacer />
                    </GridColumn>
                    <GridColumn medium={12}>
                      <div
                        style={
                          disableLogisticsSection
                            ? {
                                filter: "brightness(90%)",
                                cursor: "not-allowed",
                              }
                            : {}
                        }
                      >
                        <CardFrame
                          text={t(
                            "tickets.utilization.modal.section.logistics.title"
                          )}
                        >
                          <CardFrameContent>
                            <UnwrapGrid>
                              <Grid layout="max-width">
                                <GridColumn medium={6}>
                                  <EditSelect
                                    name="standort"
                                    translation="tickets.utilization.modal.section.logistics.fields.standort"
                                    options={Object.keys(FACILITY_GROUPS).map(
                                      (group): IEditSelectOption => ({
                                        label: FACILITY_GROUPS[group].name,
                                        value: group,
                                      })
                                    )}
                                    onChange={this.handleFieldChange}
                                    selected={this.state.standort}
                                    clearable
                                    height={60}
                                    disabled={disableLogisticsSection}
                                  />
                                </GridColumn>
                                <GridColumn medium={6}>
                                  <EditSelect
                                    name="plant"
                                    translation={
                                      !this.state.notPossibleDisposal &&
                                      !this.state.standort
                                        ? "tickets.utilization.modal.section.logistics.fields.plant"
                                        : "tickets.utilization.modal.section.logistics.fields.plant_planned"
                                    }
                                    options={this.props.plants.map(
                                      formatDisposalPlantOption
                                    )}
                                    required={
                                      requireLogisticsSection &&
                                      !this.state.standort
                                    }
                                    onChange={this.handleFieldChange}
                                    selected={this.state.plant}
                                    clearable
                                    disabled={disableLogisticsSection}
                                  />
                                </GridColumn>
                                <GridColumn medium={6}>
                                  <EditCheckbox
                                    name="streckenabholung"
                                    translation="tickets.utilization.modal.section.logistics.fields.streckenabholung"
                                    onChange={this.handleFieldChange}
                                    selected={
                                      !this.state.notPossibleDisposal &&
                                      !this.state.standort
                                    }
                                    helper={
                                      !this.state.standort
                                        ? "Wählen Sie einen Standort wenn es sich um keine Streckenabholung handelt."
                                        : "Wenn es sich um eine Streckenabholung handelt wird die Information zum Standort entfernt."
                                    }
                                    disabled={disableLogisticsSection}
                                  />
                                </GridColumn>
                                <GridColumn medium={6}>
                                  <EditNumber
                                    name="logisticCosts"
                                    translation="tickets.utilization.modal.section.logistics.fields.costs"
                                    onChange={this.handleFieldChange}
                                    value={this.state.logisticCosts}
                                    disabled={disableLogisticsSection}
                                  />
                                </GridColumn>
                              </Grid>
                            </UnwrapGrid>
                          </CardFrameContent>
                        </CardFrame>
                      </div>
                    </GridColumn>
                  </GridColumn>

                  <GridColumn medium={4}>
                    <div style={{ overflowY: "auto" }}>
                      <FindRecommendationsQuery
                        query={findRecommendationsQuery}
                        variables={recommendationQueryVariables}
                        fetchPolicy="cache-first"
                      >
                        {({ data, refetch, loading }) => {
                          if (
                            data &&
                            data.findRecommendations &&
                            data.findRecommendations.length > 0
                          ) {
                            return (
                              <div
                                style={{ overflowY: "auto", minHeight: "100%" }}
                              >
                                <h3 style={{ marginTop: 0 }}>Vorschläge</h3>
                                {data.findRecommendations.map(
                                  (recommendation, index) => (
                                    <RecommendationCard
                                      recommendation={recommendation}
                                      onSelectRecommendation={this.handleSelectRecommendation(
                                        this.props.plants
                                      )}
                                      key={index}
                                    />
                                  )
                                )}
                              </div>
                            );
                          } else if (!loading) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "50vh",
                                }}
                              >
                                Keine Vorschläge gefunden
                              </div>
                            );
                          }
                          return <PageLoadingIndicator />;
                        }}
                      </FindRecommendationsQuery>
                    </div>
                  </GridColumn>
                  <GridColumn medium={12}>
                    <Spacer />
                  </GridColumn>
                </Grid>
              </ModalDialog>
            )
          }
        </MetaDataQuery>
      </ModalTransition>
    );
  }
}

export default withTranslation()(UtilizationAction);
